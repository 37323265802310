import { MutationTree } from 'vuex'
import { DocumentsState } from '~/store/documents/state'

export default {
  setDocuments(state, { referenceId, documents }) {
    state.documents = { ...state.documents, [referenceId]: documents }
  },
  setCategories(state, { referenceId, categories }) {
    state.categories = {
      ...state.categories,
      [referenceId]: categories
    }
  }
} as MutationTree<DocumentsState>
