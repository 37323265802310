
export default {
  props: {
    /**
     * possible name combinations
     * - bell
     * - svg/states/gray
     */
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    iconConponent() {
      let name = this.name
      if (!name.includes('/')) name = `outline/${name}`
      const isHeroicon =
        name.startsWith('solid/') || name.startsWith('outline/')

      if (isHeroicon) {
        return () =>
          import(
            /* webpackChunkName: `icon/[request]` */ `heroicons/${name}.svg?inline`
          )
      } else {
        return () =>
          import(
            /* webpackChunkName: `icon/[request]` */ `~/static/${name}.svg?inline`
          )
      }
    }
  }
}
