export class InterceptorFactory {
  constructor(api) {
    this._api = api
  }

  create(interceptors) {
    interceptors.forEach((interceptor) => {
      const type = interceptor.constructor.TYPE

      if (type) {
        this._createInterceptor(this._api.interceptors[type], interceptor)
      }
    })

    return this._api
  }

  _createInterceptor(interceptors, interceptor) {
    interceptors.use(
      interceptor.onSuccess.bind(interceptor),
      interceptor.onError.bind(interceptor)
    )
  }
}
