export class Photo {
  constructor(
    public readonly base64String: string,
    public readonly format: string,
    public readonly hasError: boolean = false,
    public readonly errorMessage: string = ''
  ) {}

  public static withError(errorMessage: string): Photo {
    return new Photo('', '', true, errorMessage)
  }
}
