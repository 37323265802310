import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'

export class I18nCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'i18n')
  }

  public get(locale: string) {
    return this.api.get(`${this.path}/${locale}`)
  }
}
