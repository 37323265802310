import { ActionHandler } from 'vuex'
import { DocumentsState } from '~/store/documents/state'
import { IRootState } from '~/store'

export default {
  async request({ commit, dispatch }, referenceId: string) {
    const { locale } = this.$i18n
    const response = await this.$api.documents.get(locale, referenceId)
    const { documents, categories } = response
    const filteredDocuments = documents.filter(
      (d) => d.url || (d.fileSize === 0 && d.isStream !== false) // document should not be 0 in size if not a stream
    )
    const filteredCategories = filteredDocuments.map((d) => d.category)
    commit('setDocuments', { referenceId, documents: filteredDocuments })
    commit('setCategories', {
      referenceId,
      categories: categories.filter((c) => filteredCategories.includes(c.id))
    })

    return Promise.all([dispatch('downloads/load'), dispatch('favorites/load')])
  }
} as { [handler: string]: ActionHandler<DocumentsState, IRootState> }
