import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'
import { NotificationSetupDto } from '~/carrier/notifications/input/NotificationSetupDto'

export class NotificationsCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'notifications')
  }

  public setup(setupData: NotificationSetupDto): Promise<NotificationSetupDto> {
    return this.api.post(`${this.path}/setup`, setupData)
  }
}
