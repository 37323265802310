export type Allows = {
  rootOrgType: string
  accessType: string
}

export type UserCapabilities = Allows

/**
 * Navigation Items are found in navigation.json
 * We check for somethin that defines an `allows`
 * property.
 */
interface NavigationItem {
  allows?: Allows
}

/**
 * Checks if the User is allow to perform an action based on the given
 * allow list.
 */
export function allowsTo(
  item: NavigationItem,
  userCapabilities: UserCapabilities
) {
  const { allows } = item
  if (!allows) {
    // Skip any checking if there is no `allows` list.
    return true
  }

  const s1 = new Set(Object.values(userCapabilities))
  const s2 = new Set(Object.values(allows))

  // As long as one of the elements intersect,
  // The user has the capability to perform
  // the action.

  for (const elem of s2) {
    if (s1.has(elem)) {
      return true
    }
  }
  return false
}
