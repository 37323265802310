import Storage from '~/lib/storage'

const getCacheKey = (lang) => {
  return `messages-${lang}`
}

// TODO: This store is copied by DMV, validate what functionality can be reused.
//   It is renamed to avoid collusion with the nuxt-i18n module.
export function initLanguage({ dispatch, getters }) {
  let lang = Storage.getItem('dmvI18n/language')
  if (!lang) {
    lang = getters.getUserLanguage

    dispatch('persistLanguage', lang)
  }
  dispatch('changeLanguage', lang)
  // load fallback language messages only if there is another language selected
  if (lang !== getters.getFallbackLanguage) {
    dispatch('loadLanguage', getters.getFallbackLanguage)
  }
}

export async function changeLanguage({ dispatch, getters }, lang) {
  if (!getters.isLangSupported(lang)) {
    throw new Error('Language not supported')
  }

  await dispatch('loadLanguage', lang)
    .then(() => dispatch('setCurrentLanguage', lang))
    .catch(() => {
      throw new Error('no translations found.')
    })
}

export function setCurrentLanguage({ commit, dispatch }, lang) {
  commit('setCurrentLanguage', lang)
  dispatch('setQuasarLanguage', lang)
  // TODO: Check if this works as expected
  this.app.axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
}

/**
 * save defined language in store
 */
export function persistLanguage({ dispatch }, lang) {
  Storage.setItem('dmvI18n/language', lang)
  dispatch('changeLanguage', lang)
}

export async function loadLanguage({ dispatch }, lang) {
  await dispatch('loadLanguageFile', lang)
  await dispatch('loadApiMessages', lang)
}

export function loadLanguageFile({ commit }, lang) {
  return import(`~/lang/${lang}.json`)
    .then((messages) => commit('mergeMessages', { lang, messages }))
    .catch(() => null)
}

export async function loadApiMessages({ commit }, lang) {
  if (Storage.hasItem(getCacheKey(lang))) {
    // TODO cache validation check
    // return Storage.getItem(getCacheKey(lang))
  }

  const messages = await this.$api.i18n
    .get(lang)
    // getting messages from api was successful
    .then((response) => {
      if (typeof response.data === 'object') {
        Storage.setItem(getCacheKey(lang), response.data)
        return response.data
      } else {
        throw new TypeError('invalid response type')
      }
    })
    // fallback one, check for any cache
    .catch(() => {
      if (Storage.hasItem(getCacheKey(lang))) {
        return Storage.getItem(getCacheKey(lang))
      }
      throw new Error(`cache for lang "${lang}" not found`)
    })
    // fallback two, local file
    .catch(() => {
      // TODO: Build fails
      // return import(`src/i18n/${lang}/api.json`)
    })
    .catch(() => null)

  if (messages !== null) {
    commit('mergeMessages', { lang, messages })
  }
}

let sendMissingInterval = null

/**
 * add missing handler to send not existing messages to server
 */
export function setMissingTranslation({ dispatch, commit, state }, params) {
  // skip, if not staging system
  if (!window.vsm || !window.vsm.TYPE || window.vsm.TYPE !== 'staging') {
    return
  }

  // if we have a missing translation for dummy language 'xx', skip
  if (params.locale === 'xx') {
    return
  }

  // create a hash from given data
  const hash = `${params.locale}_${params.key}`
  const data = {
    language: params.locale,
    textkey: params.key,
    url: window.location.href
  }

  const missingTranslations = state.missingTranslations
  // check, if hash is not in current send-list OR is sent in past
  if (
    !(hash in missingTranslations) &&
    !state.sentTranslations.includes(hash)
  ) {
    commit('addMissingTranslation', { hash, data })
    missingTranslations[hash] = data
  }
  if (missingTranslations) {
    // if we have an amount of messages, send it now. otherwise later with a timeout
    if (Object.keys(missingTranslations).length >= 10) {
      dispatch('sendMissingTranslations')
    } else if (sendMissingInterval === null) {
      // run in 60 seconds
      sendMissingInterval = setTimeout(() => {
        dispatch('sendMissingTranslations')
      }, 60000)
    }
  }
}

export function sendMissingTranslations({ commit, state }) {
  const missingTranslations = state.missingTranslations

  if (missingTranslations && Object.keys(missingTranslations).length > 0) {
    // TODO: What shall we do with missing translations

    Object.keys(missingTranslations).forEach((hash) => {
      commit('setMissingTranslationSent', hash)
    })

    // clear interval, when it's set
    if (sendMissingInterval !== null) {
      clearTimeout(sendMissingInterval)
      sendMissingInterval = null
    }
    commit('clearMissingTranslations')
  }
}
