import { get } from 'lodash-es'
import { Interceptor } from '~/plugins/api/api-factory/interceptors/interceptor'
import { types } from '~/plugins/api/api-factory/interceptors/constants'

export class TimeoutErrorInterceptor extends Interceptor {
  static TYPE = types.RESPONSE
  static _ERROR_CODE = 'ECONNABORTED'

  constructor(store, i18n) {
    super()
    this._store = store
    this._i18n = i18n
  }

  onError(error) {
    const code = get(error, ['code'], null)

    if (code === TimeoutErrorInterceptor._ERROR_CODE) {
      this._store.dispatch(
        'notify/error',
        this._i18n.tc('warnings.common_timeout_error')
      )
    }

    return super.onError(error)
  }
}
