import openUrl from '~/lib/url/openUrl'

const handleEtkRoute = async ({
  route: { name },
  app: { i18n },
  error,
  $api,
  from,
  redirect
}) => {
  if (name === 'parts-catalog') {
    try {
      const { data } = await $api.etk.get(undefined, i18n.locale)
      if (from.name === name) {
        redirect(data.url)
      } else {
        openUrl(data.url, '_blank')
        redirect(from)
      }
    } catch (e) {
      error({ statusCode: 404 })
    }
  }
}

export default handleEtkRoute
