export interface IColorMap {
  [key: string]: string
}

export const colorMap = {
  a: 'bg-blue-500',
  b: 'bg-blue-200',
  c: 'bg-yellow-500',
  d: 'bg-purple-500',
  e: 'bg-purple-400',
  f: 'bg-pink-600',
  g: 'bg-indigo-500',
  h: 'bg-gray-600',
  i: 'bg-gray-500',
  j: 'bg-indigo-400',
  k: 'bg-red-200',
  l: 'bg-yellow-800',
  m: 'bg-indigo-200',
  n: 'bg-indigo-600',
  o: 'bg-green-600',
  p: 'bg-blue-800',
  q: 'bg-green-500',
  r: 'bg-gray-400',
  s: 'bg-yellow-300',
  t: 'bg-gray-700',
  u: 'bg-gray-800',
  v: 'bg-blue-700',
  w: 'bg-red-700',
  x: 'bg-green-800',
  y: 'bg-pink-200',
  z: 'bg-red-400',
  0: 'bg-red-300',
  1: 'bg-blue-400',
  2: 'bg-pink-700',
  3: 'bg-red-500',
  4: 'bg-gray-300',
  5: 'bg-red-600',
  6: 'bg-pink-400',
  7: 'bg-green-400',
  8: 'bg-purple-200',
  9: 'bg-red-800',
  '!': 'bg-gray-200',
  '#': 'bg-indigo-300',
  $: 'bg-yellow-200',
  '%': 'bg-blue-300',
  '&': 'bg-purple-800',
  "'": 'bg-yellow-700',
  '+': 'bg-pink-300',
  '/': 'bg-purple-700',
  '=': 'bg-green-700',
  '?': 'bg-purple-600',
  '^': 'bg-green-300',
  _: 'bg-green-200',
  '`': 'bg-indigo-800',
  '{': 'bg-pink-500',
  '|': 'bg-yellow-600',
  '}': 'bg-purple-300',
  '~': 'bg-indigo-700',
  '-': 'bg-blue-600',
  default: 'bg-yellow-400'
}
