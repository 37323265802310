export class ActionScheduler {
  constructor(scheduler, modules) {
    this.scheduler = scheduler
    this.modules = modules
  }

  create(store) {
    const actions = this.getScheduledActions()
    actions.forEach(this.schedule.bind(this, store))
  }

  schedule(store, action) {
    const { name, delay } = action
    this.scheduler.schedule(store.dispatch.bind(store, name), delay)
  }

  getScheduledActions() {
    return Object.values(this.modules)
      .filter((module) => !!module.actionsScheduled)
      .map(({ actionsScheduled }) => actionsScheduled)
      .flat()
  }
}
