import VueI18n from 'vue-i18n'
import { NuxtAppOptions, Plugin } from '@nuxt/types'
import { Native } from '~/plugins/platform/native'
import { Connection } from '~/plugins/platform/Connection'

export class Platform {
  // TODO: Add implementation, network state is currently not used
  private networkState = 'Unknown connection'
  public readonly native: Native

  constructor(
    private readonly i18n: VueI18n,
    private readonly app: NuxtAppOptions
  ) {
    this.native = new Native(i18n, app)
  }

  public get isNative(): boolean {
    return this.native.isNative
  }

  public get isOffline() {
    return this.networkState === Connection.Offline
  }
}

export class PlatformPlugin {
  private static readonly NUXT_PLUGIN_KEY = 'platform'

  static install: Plugin = ({ i18n, app }, inject) => {
    inject(PlatformPlugin.NUXT_PLUGIN_KEY, PlatformPlugin.setup(i18n, app))
  }

  private static setup(i18n: VueI18n, app: NuxtAppOptions) {
    return new Platform(i18n, app)
  }
}

export default PlatformPlugin.install
