import { isNumber, isString, isEmpty } from 'lodash-es'
import { IDocumentDto } from '~/carrier/documents/input/IGetDocumentsDto'

export class Document {
  constructor(
    public readonly id = '',
    public readonly name = '',
    public readonly category: string = '',
    public readonly lastChange: string = '',
    public readonly fileExtension = '',
    public readonly fileName = '',
    public readonly language = '',
    public readonly fileSize = 0,
    public readonly teaser = '',
    public readonly text = '',
    public readonly thumbnailUrl = '',
    public readonly url = '',
    public readonly isStream = false
  ) {}

  public static fromJson(dto: IDocumentDto): Document {
    return new Document(
      dto.id,
      dto.name,
      dto.category,
      dto.changed,
      dto.ext || '',
      Document.createFileName(dto.id, dto.ext),
      dto.lang,
      Document.createSize(dto.size),
      dto.teaser,
      dto.text,
      dto.thumb || '',
      dto.url,
      !!dto.stream
    )
  }

  public get isDownloadable(): boolean {
    return this.fileSize > 0
  }

  private static createSize(size: number | string) {
    if (isNumber(size)) {
      return size
    }

    if (isEmpty(size)) {
      return 0
    }

    if (isString(size)) {
      return parseInt(size)
    }

    return 0
  }

  private static createFileName(id: string, ext: string | null) {
    if (isString(ext)) {
      return `${id}.${ext.toLowerCase()}`
    }

    return ''
  }
}
