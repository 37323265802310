import { AxiosInstance } from 'axios'
import { Subscription } from '~/models/organization/subscription/Subscription'
import { Carrier } from '~/carrier/Carrier'
import { IGetSubscriptionDto } from '~/carrier/organizations/subscriptions/input/IGetSubscriptionDto'
import { IPostSubscriptionDto } from '~/carrier/organizations/subscriptions/output/IPostSubscriptionDto'

export class SubscriptionsCarrier extends Carrier {
  constructor(api: AxiosInstance, protected readonly organizationPath: string) {
    super(api, 'subscriptions')
  }

  public async get(organizationId: string): Promise<Subscription[]> {
    const path = `${this.organizationPath}/${organizationId}/${this.path}`
    const { data = [] } = await this.api.get<IGetSubscriptionDto[]>(path)
    return data.map(Subscription.fromJson)
  }

  public async post(organizationId: string, dto: IPostSubscriptionDto) {
    const path = `${this.organizationPath}/${organizationId}/${this.path}`
    const { data } = await this.api.post<IGetSubscriptionDto>(path, dto)
    return Subscription.fromJson(data)
  }

  public delete(organizationId: string, id: string) {
    const path = `${this.organizationPath}/${organizationId}/${this.path}/${id}`
    return this.api.delete(path)
  }
}
