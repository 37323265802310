import { MutationTree } from 'vuex'
import { IPromiseDialogState } from '~/store/promiseDialog/IPromiseDialogState'

export default {
  SET_OPEN: (state, value) => {
    state.open = value
  },
  SET_OPTIONS: (state, value) => {
    state.options = value
  },
  SET_RESOLVE: (state, value) => {
    state.resolve = value
  }
} as MutationTree<IPromiseDialogState>
