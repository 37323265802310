import { Download } from '~/models/document/Download'
import { IFileStorage } from '~/plugins/platform/native/api/file-storage/IFileStorage'

export class DownloadsStore {
  private static readonly DOWNLOADS_DIRECTORY = 'documents'

  constructor(
    private readonly store: IFileStorage,
    private readonly isNative: boolean
  ) {}

  public async getDownloads(): Promise<Download[]> {
    const hasDownloads = await this.hasDownloads

    if (!hasDownloads) {
      return Promise.resolve([])
    }

    const directory = DownloadsStore.DOWNLOADS_DIRECTORY
    const fileNames = await this.store.getFileNames(directory)

    return fileNames.map((fileName) => new Download(fileName))
  }

  public async save(download: Download): Promise<void> {
    if (!this.isNative) return

    await this.store.save(download.path, download.data)
  }

  public delete(download: Download): Promise<void> {
    if (!this.isNative) return Promise.resolve()

    return this.store.delete(download.path)
  }

  public open(download: Download): Promise<void> {
    if (!this.isNative) return Promise.resolve()

    return this.store.open(download.path)
  }

  private get hasDownloads(): Promise<boolean> {
    if (!this.isNative) return Promise.resolve(false)

    return this.store.has(DownloadsStore.DOWNLOADS_DIRECTORY)
  }
}
