import { Camera, CameraResultType } from '@capacitor/camera'
import VueI18n from 'vue-i18n'
import { Photo } from '~/plugins/platform/native/api/camera/photo'
import { TextResources } from '~/plugins/platform/native/api/camera/text-resources'
import { TextResourceKey } from '~/plugins/platform/native/api/camera/text-resources/TextResourceKey'

export class CameraApi {
  private static readonly PHOTO_QUALITY = 100
  private static readonly PHOTO_OPTIONS = {
    quality: CameraApi.PHOTO_QUALITY,
    resultType: CameraResultType.Base64,
    webUseInput: true
  }

  constructor(private readonly i18n: VueI18n) {}

  public async getPhoto(): Promise<Photo> {
    try {
      const photo = await Camera.getPhoto(this.options)
      const { base64String = '', format = '' } = photo

      return new Photo(base64String, format)
    } catch (error: any) {
      return Photo.withError(error.message)
    }
  }

  private get options() {
    return {
      ...CameraApi.PHOTO_OPTIONS,
      ...this.textResources
    }
  }

  private get textResources(): TextResources {
    // TODO: Currently it is not easy possible to translate the error messages
    //  of the Capacitor Camera plugin, because all errors are hard coded.
    //  But Capacitor plans to support i18n at least for new plugins.
    //  @see: https://github.com/ionic-team/capacitor-site/issues/95
    return {
      promptLabelHeader: this.i18n.tc(TextResourceKey.PromptLabelHeader),
      promptLabelCancel: this.i18n.tc(TextResourceKey.PromptLabelCancel),
      promptLabelPhoto: this.i18n.tc(TextResourceKey.PromptLabelPhoto),
      promptLabelPicture: this.i18n.tc(TextResourceKey.PromptLabelPicture)
    }
  }
}
