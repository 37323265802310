import { AxiosInstance } from 'axios'
import { get } from 'lodash-es'
import { Carrier } from '~/carrier/Carrier'
import {
  ICategoryDto,
  IDocumentDto
} from '~/carrier/documents/input/IGetDocumentsDto'
import { Method } from '~/carrier/Method'
import { ResponseType } from '~/carrier/ResponseType'
import { Document } from '~/models/document/Document'
import { Category } from '~/models/document/Category'

export class DocumentsCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'documents')
  }

  public async get(locale: string, referenceId: string) {
    const configuration = { params: { lang: locale, refid: referenceId } }
    const { data } = await this.api.get(this.path, configuration)
    const documentsDto: IDocumentDto[] = get(data, ['documents'], [])
    const categoriesDto: ICategoryDto[] = get(data, ['categories'], [])
    const documents = documentsDto.map(Document.fromJson)
    const categories = categoriesDto.map(Category.fromJson)

    return { documents, categories }
  }

  public download(url: string) {
    return this.api({
      baseURL: url,
      method: Method.Get,
      responseType: ResponseType.Blob,
      timeout: 300000 // 5 minutes
    })
  }
}
