import { MutationTree, Mutation } from 'vuex'
import { WarrantyState } from '~/store/warranty/state'

type WarrantyMutation = Mutation<WarrantyState>

export const setProducts: WarrantyMutation = (state, products) => {
  state.products = products
}

export const updateProduct: WarrantyMutation = (state, product) => {
  const hasProduct = !!state.products.find(({ sn }) => product.sn === sn)

  if (!hasProduct) {
    state.products.push(product)
  }

  state.products = state.products.map((currentProduct) =>
    product.sn === currentProduct.sn ? product : currentProduct
  )
}

export const removeProduct: WarrantyMutation = (state, serialNo) => {
  const index = state.products.findIndex(({ sn }) => serialNo === sn)
  if (index === -1) return

  state.products.splice(index, 1)
}

export default {
  setProducts,
  updateProduct,
  removeProduct
} as MutationTree<WarrantyState>
