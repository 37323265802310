import { AxiosInstance } from 'axios'
import Thing from '~/models/thing/Thing'
import { Carrier } from '~/carrier/Carrier'

export class ThingsCarrier extends Carrier {
  constructor(api: AxiosInstance, protected readonly organizationPath: string) {
    super(api, 'things')
  }

  public get(organizationId: string) {
    const path = `${this.organizationPath}/${organizationId}/${this.path}`
    return this.api.get(path)
  }

  public post(organizationId: string, things: Thing[]) {
    const path = `${this.organizationPath}/${organizationId}/${this.path}`
    return this.api.post(path, { things })
  }
}
