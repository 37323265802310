
import { notificationIconMap } from '~/lib/utils/icon'

export default {
  props: {
    title: { type: String, default: undefined },
    message: { type: String, default: undefined },
    accept: {
      type: [Object, Boolean],
      default: () => ({
        label: 'buttons.confirm',
        callback: () => {}
      })
    },
    dismiss: {
      type: [Object, Boolean],
      default: () => ({
        label: 'buttons.discard',
        callback: () => {}
      })
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'info',
      validator(val) {
        return ['error', 'warning', 'info', 'success', 'dialog'].includes(val)
      }
    }
  },
  computed: {
    meta() {
      return notificationIconMap[this.type]
    }
  },
  methods: {
    close(callback) {
      if (!callback && this.isDialog) return
      setTimeout(() => {
        this.$emit('close')
      }, 100)

      try {
        callback()
      } catch {}
    }
  }
}
