import Storage from '~/lib/storage'

// TODO how can we get rid of that? Is is secure anyway?
export default function getLoginState() {
  let state = Storage.getItem('login/state')
  if (!state) {
    const arr = new Uint8Array(16)
    window.crypto.getRandomValues(arr)
    state = Array.from(arr, (d) => ('0' + d.toString(16)).substr(-2)).join('')
    Storage.setItem('login/state', state)
  }
  return state
}
