import { setLocale, getLocale } from '~/plugins/i18n/persistLocale'

const handleLocale = ({ app: { i18n } }) => {
  const storedLocale = getLocale()
  if (storedLocale !== null) {
    setLocale(i18n, storedLocale)
  }
}

export default handleLocale
