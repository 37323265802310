import Vue from 'vue'
import {
  formatRelative,
  intlFormat,
  formatDuration,
  formatDistanceToNow
} from 'date-fns'

const dateFnsLocales = {
  da: () => import('date-fns/locale/da'),
  de: () => import('date-fns/locale/de'),
  en: () => import('date-fns/locale/en-US'),
  es: () => import('date-fns/locale/es'),
  fr: () => import('date-fns/locale/fr'),
  it: () => import('date-fns/locale/it'),
  ko: () => import('date-fns/locale/ko'),
  nl: () => import('date-fns/locale/nl'),
  pl: () => import('date-fns/locale/pl'),
  pt: () => import('date-fns/locale/pt'),
  ru: () => import('date-fns/locale/ru'),
  zh: () => import('date-fns/locale/zh-CN')
}

export default async ({ app = {} }) => {
  let locale = await dateFnsLocales[app.i18n.locale]()
  app.i18n.onBeforeLanguageSwitch = async (_oldLocale, newLocale) => {
    locale = await dateFnsLocales[newLocale]()
  }

  app.$filters = Object.assign(app.$filters || {}, {
    parseInt: (val) => parseInt(val),
    date: (val) => app.$filters.format(val),
    time: (val) => {
      val = normalizeDateInput(val)
      return app.$filters.format(val, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
    },
    datetime: (val) => {
      val = normalizeDateInput(val)
      return app.$filters.format(val, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
    },
    format: (
      val,
      format = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    ) => {
      try {
        val = normalizeDateInput(val)
        return intlFormat(new Date(val), format, {
          locale: locale.code
        })
      } catch (err) {
        return val
      }
    },
    dateRelative: (val) => {
      try {
        val = normalizeDateInput(val)
        return formatRelative(new Date(val), new Date(), {
          locale
        })
      } catch (err) {
        return val
      }
    },
    runtime(val, unit) {
      const normalizedUnit = /(\w+?)([sS]\b)/.test(unit) ? unit : `${unit}s`
      return formatDuration(
        {
          [normalizedUnit]: parseInt(val)
        },
        {
          format: [normalizedUnit],
          zero: true,
          locale
        }
      )
    },
    dateDiff(date) {
      return formatDistanceToNow(date, {
        addSuffix: true,
        includeSeconds: false,
        locale
      })
    },
    lastSeen(val) {
      return `${app.i18n.t(
        'source_component.last_seen.label'
      )}: ${app.$filters.dateRelative(val)}`
    },
    // Format bytes as human-readable text.
    humanFileSize(bytes) {
      if (bytes <= 0) return 0

      const i = Math.floor(Math.log(bytes) / Math.log(1024))
      const value = (bytes / Math.pow(1024, i)).toFixed(2)
      const unit = ['B', 'KB', 'MB', 'GB', 'TB'][i]
      return `${value} ${unit}`
    }
  })

  // add all methods as filters on each vue component
  Object.keys(app.$filters).forEach((key) => {
    Vue.filter(key, app.$filters[key])
  })

  function normalizeDateInput(val) {
    // fix date format when unix timestamp is given instead of iso date string
    if (
      typeof val === 'string' &&
      Array.isArray(val.match(/^(\d{10}|\d{13})$/))
    ) {
      if (val.length === 10) val += '000'
      val = parseInt(val)
    }
    return val
  }

  Vue.prototype.$filters = app.$filters

  return app
}
