import { NuxtAppOptions } from '@nuxt/types'
import { initializeApp } from 'firebase/app'
import {
  FirebaseMessaging,
  GetTokenOptions
} from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { getEnvSync } from '~/plugins/config/loadEnvConfig'

const SERVICE_WORKER_PATH = `.firebase-messaging-sw.js`

type NotificationData = {
  route: string
}

export class PushNotificationsApi {
  private _token: string = ''
  private platform: string
  private isNative: boolean
  private hasServiceWorker: boolean
  private isMobileWeb: boolean = false
  constructor(private readonly app: NuxtAppOptions) {
    this.platform = Capacitor.getPlatform()
    this.isNative = Capacitor.isNativePlatform()
    this.hasServiceWorker = 'serviceWorker' in navigator
  }

  private async checkPermissions() {
    try {
      if (!this.isNative && Notification === undefined) return false
      const permission = await FirebaseMessaging.checkPermissions()
      return permission.receive === 'granted'
    } catch (e) {
      // catch 'Notification is undefined' error
    }
  }

  private async requestPermissions() {
    try {
      if (!this.isNative && Notification === undefined) return false
      let permission = await FirebaseMessaging.checkPermissions()
      if (permission.receive === 'prompt')
        permission = await FirebaseMessaging.requestPermissions()
      return permission.receive === 'granted'
    } catch (e) {
      // catch 'Notification is undefined' error
    }
  }

  private async checkMobileWeb() {
    if (this.isNative) return
    const { operatingSystem } = await Device.getInfo()
    this.isMobileWeb = ['android', 'ios'].includes(operatingSystem)
  }

  private async checkWebHasServiceWorker() {}

  public async setup() {
    await this.checkMobileWeb()
    if (this.isMobileWeb) return

    try {
      const options: GetTokenOptions = {}
      if (!this.isNative && this.hasServiceWorker) {
        const config = this.app.$envConfig.firebaseConfig
        if (!config) return

        initializeApp(config)
        if (!(await this.checkPermissions())) return

        options.serviceWorkerRegistration = await navigator.serviceWorker.register(
          `${window.location.origin}/${getEnvSync()}${SERVICE_WORKER_PATH}`
        )
        options.vapidKey = this.app.$envConfig.FIREBASE_VAPID_KEY
        await navigator.serviceWorker.ready // prevent calling firebase until service worker is ready
      }

      if (!(await this.checkPermissions())) return

      const { token } = await FirebaseMessaging.getToken(options)
      this._token = token
      // send it to the backend
      this.app.$api.notifications.setup({
        device_type: this.platform,
        token
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  public async addListeners() {
    if (this.isMobileWeb) return

    if (!this.isNative && this.hasServiceWorker) {
      if (!this.app.$envConfig.firebaseConfig) return

      document.addEventListener(
        'click',
        async () => {
          if (await this.checkPermissions()) return
          if (await this.requestPermissions()) await this.setup()
        },
        { once: true }
      )
      navigator.serviceWorker.addEventListener('message', (event) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body
        })
      })
      return
    }

    // mobile
    if (!(await this.requestPermissions())) return
    await this.setup()

    FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
      const {
        actionId,
        notification: { data }
      } = event
      const notificationData: NotificationData = data as NotificationData
      if (actionId === 'tap' && !!notificationData.route) {
        this.app.router?.push(notificationData.route)
      }
    })

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) FirebaseMessaging.removeAllDeliveredNotifications()
    })
  }

  public get token() {
    return this._token
  }
}
