
import { stringToColor } from '~/lib/utils/string'

const SIZE = {
  s: {
    element: 'w-5 h-5 lg:h-6 lg:w-6',
    text: 'text-xs'
  },
  l: {
    element: 'h-10 w-10 lg:h-9 lg:w-9',
    text: 'text-l'
  }
}

export default {
  props: {
    email: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 's'
    }
  },
  data() {
    return { SIZE }
  },
  computed: {
    cssColorClasses() {
      const bgColor = this.getCssBgColorClass(this.email)
      const textColor = this.getCssTextColorClass(bgColor)

      return `${bgColor} ${textColor}`
    },
    firstCharacter() {
      return this.email[0]?.toUpperCase()
    }
  },
  methods: {
    getCssBgColorClass(email) {
      return stringToColor(email)
    },
    getCssTextColorClass(cssBgColorClass) {
      const lightness = this.getLightness(cssBgColorClass)

      if (lightness > 400) {
        return 'text-gray-200'
      }

      return 'text-gray-800'
    },
    getLightness(cssBgColorClass) {
      return parseInt(cssBgColorClass.split('-').pop())
    }
  }
}
