import VueI18n from 'vue-i18n'
import { NuxtAppOptions } from '@nuxt/types'
import { Capacitor } from '@capacitor/core'
import { CameraApi } from '~/plugins/platform/native/api/camera'
import { FileStorageApi } from '~/plugins/platform/native/api/file-storage'
import { ScannerApi } from '~/plugins/platform/native/api/scanner'
import { PushNotificationsApi } from '~/plugins/platform/native/api/notifications'

/**
 * Provides a mobile device native API.
 */
export class Native {
  public readonly camera: CameraApi
  public readonly fileStorage: FileStorageApi | undefined = undefined
  public readonly scanner: ScannerApi | undefined = undefined
  public readonly notifications: PushNotificationsApi | undefined = undefined

  constructor(i18n: VueI18n, app: NuxtAppOptions) {
    this.camera = new CameraApi(i18n)
    this.notifications = new PushNotificationsApi(app)
    if (!this.isNative) return
    this.fileStorage = new FileStorageApi()
    this.scanner = new ScannerApi(i18n)
  }

  public get isNative() {
    return Capacitor.isNativePlatform()
  }
}
