import { StoredDocument } from './stored-document/StoredFavorite'
import { Favorite } from '~/models/document/Favorite'
import { IStoredFavorite } from '~/store/documents/favorites/local-store/models/stored-favorite/IStoredFavorite'

export class StoredFavorite {
  public static toFavorite(storedFavorite: IStoredFavorite): Favorite {
    const document = StoredDocument.toDocument(storedFavorite.document)

    return new Favorite(storedFavorite.productId, document)
  }
}
