import { ActionTree } from 'vuex'
import { IRootState } from '~/store'
import { IConnectionState } from '~/store/connection/IConnectionState'

const actions: ActionTree<IConnectionState, IRootState> = {
  check({ commit }) {
    // check for cordova platform and use different type of detection
    if (this.$platform.isNative) {
      document.addEventListener('deviceready', () => {
        // TODO: Platform will never be set offline
        if (this.$platform.isOffline) {
          commit('online', false)
        } else {
          commit('online', true)
        }

        document.addEventListener(
          'offline',
          () => {
            commit('online', false)
          },
          false
        )
        document.addEventListener(
          'online',
          () => {
            commit('online', true)
          },
          false
        )
      })
    } else {
      commit('online', navigator.onLine)
      window.addEventListener('offline', () => {
        commit('online', false)
      })
      window.addEventListener('online', () => {
        commit('online', true)
      })
    }
  }
}

export default actions
