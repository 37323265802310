import { ActionHandler } from 'vuex'
import { downloadUrl } from '~/store/documents/downloads/utils'
import { DownloadsStore } from '~/store/documents/downloads/native-store/DownloadsStore'
import { IRootState } from '~/store'
import { DownloadsState } from '~/store/documents/downloads/state'
import { Document } from '~/models/document/Document'
import { FileStorageApi } from '~/plugins/platform/native/api/file-storage'
import { Download } from '~/models/document/Download'

export default {
  async load({ commit }) {
    const { isNative } = this.$platform
    const store = new DownloadsStore(new FileStorageApi(), isNative)
    const downloads = await store.getDownloads()

    commit('set', downloads)
  },
  async download({ dispatch }, document: Document) {
    const { url, fileName } = document
    const { isNative } = this.$platform

    if (isNative) {
      const { data } = await this.$api.documents.download(url)

      return dispatch('add', new Download(document.fileName, data))
    }

    return downloadUrl(url, fileName)
  },
  async add({ commit }, download: Download) {
    const { isNative } = this.$platform
    const store = new DownloadsStore(new FileStorageApi(), isNative)
    await store.save(download)

    commit('add', download)
  },
  async remove({ commit }, download: Download) {
    const { isNative } = this.$platform
    const store = new DownloadsStore(new FileStorageApi(), isNative)
    await store.delete(download)

    commit('remove', download)
  },
  open(_, document: Document) {
    const { isNative } = this.$platform
    const { url } = document

    if (isNative) {
      const store = new DownloadsStore(new FileStorageApi(), isNative)
      return store.open(new Download(document.fileName))
    }

    window.open(url, '_blank')
  }
} as { [handler: string]: ActionHandler<DownloadsState, IRootState> }
