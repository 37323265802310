import { GetterTree } from 'vuex'
import { IRootState } from '~/store'
import { ProductsState } from '~/store/documents/favorites/products/state'
import { Product } from '~/models/product/Product'

export default {
  products: ({ products }) => products,
  has: (_, { products }) => (product: Product) => {
    if (!products.length) return false

    return !!products.find(product.is.bind(product))
  },
  hasFavorite: (_, __, ___, rootGetters) => (product: Product) => {
    const byProduct = rootGetters['documents/favorites/byProduct']
    return byProduct(product).length > 0
  }
} as GetterTree<ProductsState, IRootState>
