import { NuxtConfig } from '@nuxt/types'
import { Store } from 'vuex'
import VueI18n from 'vue-i18n'
import { AxiosInstance, AxiosStatic } from 'axios'
import { IRootState } from '~/store'
import { IApiFactory } from '~/plugins/api/api-factory/IApiFactory'

export class ApiFactory implements IApiFactory {
  constructor(
    // TODO: Naming convention for private instance variables can be removed
    protected readonly _config: NuxtConfig,
    protected readonly _store: Store<IRootState>,
    protected readonly _i18n: VueI18n
  ) {}

  create(axios: AxiosStatic) {
    const api = axios.create({
      baseURL: this._baseUrl,
      headers: this._headers,
      timeout: 10000
    })

    return this._addInterceptors(api)
  }

  get _headers() {
    return null
  }

  get _baseUrl() {
    return ''
  }

  _addInterceptors(api: AxiosInstance) {
    return api
  }
}
