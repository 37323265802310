export type ServicePartnerDto = {
  id: string
  name?: string
}

export class ServicePartner {
  constructor(public readonly id: string, public readonly name: string = '') {}

  public static fromObject(dto: ServicePartnerDto) {
    return new ServicePartner(dto.id, dto.name)
  }
}
