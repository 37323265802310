import { get } from 'lodash-es'
import { Interceptor } from '~/plugins/api/api-factory/interceptors/interceptor'
import { types } from '~/plugins/api/api-factory/interceptors/constants'

export class MissingTokenInterceptor extends Interceptor {
  static TYPE = types.RESPONSE
  static _ERROR_TYPE = 'MISSING_AUTHENTICATION_TOKEN'
  static _ERROR_STATUS = 403

  constructor(store) {
    super()
    this._store = store
  }

  onError(error) {
    // TODO: Add logger for errors
    const { _ERROR_STATUS, _ERROR_TYPE } = MissingTokenInterceptor
    const status = get(error, ['response', 'status'], null)
    const errorType = get(error, ['response', 'headers', 'x-ErrorType'], null)

    if (status === _ERROR_STATUS && errorType === _ERROR_TYPE) {
      // TODO: Investigate if user/logout token can be moved to user/refreshAccessToken
      this._store
        .dispatch('user/refreshAccessToken')
        .catch(() => this._store.dispatch('user/logout'))
    }

    return super.onError(error)
  }
}
