import { Context } from '@nuxt/types/app'

const handleRouteTracking = ({ route, from, $mixpanel }: Context) => {
  if (!$mixpanel || !route.name) return
  $mixpanel.trackNavigateEvent({
    NavigateFrom: from.name ?? '',
    NavigateTo: route.name
  })
}

export default handleRouteTracking
