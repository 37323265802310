export default (arr) => {
  const tree = []
  const mappedArr = {}
  let mappedElem

  if (!Array.isArray(arr)) {
    return []
  }

  for (let i = 0, len = arr.length; i < len; i++) {
    if (arr[i].id) {
      mappedArr[arr[i].id] = { ...arr[i] }
      mappedArr[arr[i].id].corg = []
    }
  }

  for (const id in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      mappedElem = mappedArr[id]
      if (mappedElem.porg) {
        if (mappedArr[mappedElem.porg]) {
          mappedArr[mappedElem.porg].corg.push(mappedElem)
        }
      } else {
        tree.push(mappedElem)
      }
      delete mappedElem.porg
    }
  }
  return tree
}
