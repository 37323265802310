import { MutationTree } from 'vuex'
import { SubscriptionState } from '~/store/organization/subscriptions/state'

export default {
  set(state, subscriptions) {
    state.subscriptions = subscriptions
  },
  add(state, subscription) {
    state.subscriptions.unshift(subscription)
  },
  delete(state, subscriptionId) {
    state.subscriptions = state.subscriptions.filter(
      ({ id }) => id !== subscriptionId
    )
  }
} as MutationTree<SubscriptionState>
