import { Document } from '~/models/document/Document'
import { Product } from '~/models/product/Product'

export class Favorite {
  constructor(
    public readonly productId: string,
    public readonly document: Document
  ) {}

  public is(favorite: Favorite): boolean {
    return (
      favorite.productId === this.productId &&
      favorite.document.id === this.document.id
    )
  }

  public hasProduct(product: Product) {
    return this.productId === product.ref
  }

  public isNot(favorite: Favorite): boolean {
    return !this.is(favorite)
  }
}
