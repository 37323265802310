import { Context } from '@nuxt/types/app'

const handleWelcomeRedirect = ({ route, store, redirect }: Context) => {
  if (route.name !== 'welcome') return

  const redirectFrom = sessionStorage.getItem('redirectFrom') ?? '/'

  if (store.getters['user/isLoggedIn']) redirect(redirectFrom)
}

export default handleWelcomeRedirect
