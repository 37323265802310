import { MutationTree } from 'vuex'
import { FavoritesState } from '~/store/documents/favorites/state'
import { Favorite } from '~/models/document/Favorite'

export default {
  set(state, favorites) {
    state.favorites = favorites
  },
  add(state, favorite: Favorite) {
    state.favorites.push(favorite)
  },
  remove(state, favorite: Favorite) {
    state.favorites = state.favorites.filter(favorite.isNot.bind(favorite))
  }
} as MutationTree<FavoritesState>
