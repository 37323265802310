import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'
import { Product } from '~/models/product/Product'
import { SearchProductDto } from '~/carrier/products/input/SearchProductDto'

export class ProductsCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'products')
  }

  public async search(locale: string, query: string): Promise<Product[]> {
    const config = { params: { query, lang: locale } }
    const { data } = await this.api.get<SearchProductDto[]>(this.path, config)
    return data.map(Product.fromObject)
  }

  public extendWarranty(product: Product) {
    return this.api.post(`product/register`, product)
  }
}
