import { IGetSubscriptionDto } from '~/carrier/organizations/subscriptions/input/IGetSubscriptionDto'
import { Protocol } from '~/models/organization/subscription/Protocol'

export class Subscription {
  constructor(
    public readonly id: string,
    public readonly endpoint: string,
    public protocol: Protocol,
    public readonly pending: boolean = true,
    public readonly orgId: string
  ) {}

  public static fromJson(dto: IGetSubscriptionDto) {
    return new Subscription(
      dto.SubscriptionArn,
      dto.Endpoint,
      dto.Protocol,
      dto.PendingConfirmation,
      dto.Organization
    )
  }

  public get email() {
    if (this.isEmailProtocol) {
      return this.endpoint
    }

    return ''
  }

  private get isEmailProtocol() {
    return this.protocol === Protocol.Email
  }
}
