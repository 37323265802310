export default async (path) => {
  let content
  try {
    // Use 'eager' instead of default 'lazy', because in some browsers the
    // dynamic import stuck in pending and will not resolve.
    // In this case it is ok to use eager, because the file is definitely
    // required.
    content = await import(
      /* webpackChunkName: `config/[request]`, webpackMode: 'eager' */ `~/config/${path}`
    ).then((module) => module.default)
  } catch (err) {
    content = {}
  }
  return content
}
