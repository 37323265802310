export enum Role {
  Admin = 'admin',
  User = 'user',
  Technician = 'technician'
}

export function getRoles(isServicePartner: boolean = false) {
  const roles = [Role.Admin, Role.User]
  if (isServicePartner) {
    roles.push(Role.Technician)
  }
  return roles
}
