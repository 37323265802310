import { Interceptor } from '~/plugins/api/api-factory/interceptors/interceptor'
import { types } from '~/plugins/api/api-factory/interceptors/constants'

export class TokenInterceptor extends Interceptor {
  static TYPE = types.REQUEST

  constructor(store) {
    super()
    this._store = store
  }

  onSuccess(request) {
    if (this._hasToken) {
      request.headers.Authorization = `Bearer ${this._token}`
    }

    return request
  }

  get _hasToken() {
    return this._token !== null && this._token.length > 0
  }

  get _token() {
    return this._store.getters['user/token']
    // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImRsYy41IG9yZyB1c2VyIHZzbSB0ZXN0IHVzZXIud3JpdGUgbWFpbnQiLCJpc3MiOiJodHRwczovL2FwaS5kdWVycmRlbnRhbC5jb20vb2F1dGgyIiwiZXhwIjoxNjE3MTk5MDYxLCJpYXQiOjE2MTQ2MDcwNjEsInN1YiI6IjU4NGYxYTBmLTA5NTgtNDI5My1iNmQzLWFlMDgwNDM3YjFkMSIsIm9yZyI6IjUxOGFhZWVmLWM0YWUtNDQ1YS1iMzViLWQwZmQyZGI2MDJiZCIsInBvcmciOiIiLCJyb3JnIjoiNTE4YWFlZWYtYzRhZS00NDVhLWIzNWItZDBmZDJkYjYwMmJkIiwicm9sZSI6ImFkbWluIn0.iNGtFiEmbOjLaTgcqX602AJ6bSQoX_IqIXnRPz3KJNWUjscKU19JnRVSiY9i7CNXGd24j3CvSW8lXGQX0mS4-DpayFqWD4nixeI1OVIc_GlWuxH6G-qrD2R-hC-J7QQJZBqFZMWLd8Os2Gnfi-lzj2Gv3hhqwypLkf09ZWix5D4'
  }
}
