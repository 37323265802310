import Vue from 'vue'

export function addMessage(state, { text, severity }) {
  severity = severity || 'info'
  if (text) {
    state.messages.push({ severity, text, time: Date.now() })
  }
}

export function clear(state) {
  Vue.set(state, 'messages', [])
}
