import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import loadConfigFile from '~/plugins/config/loadConfigFile'
import { host } from '~/plugins/location'
import local from '~/config/env/local/default'
import localAndroid from '~/config/env/local/android'
import localCi from '~/config/env/local/ci'
import dev from '~/config/env/dev/default'
import e2e from '~/config/env/e2e/default'
import stage from '~/config/env/stage/default'
import prod from '~/config/env/prod/default'

const DEFAULT_ENV = 'prod'
const configList = { dev, local, localAndroid, localCi, prod, stage, e2e }
let loadedEnv = null

export default async (branding) => {
  const env = await getEnv()
  loadedEnv = env
  const brandingConf = await loadConfigFile(`env/${env}/${branding}.json`)

  return { ...configList[env], ...brandingConf }
}

export const getEnv = async () => {
  // on native apps determine environment by app id
  if (Capacitor.isNativePlatform()) {
    const { id, name } = await App.getInfo()
    if (id.indexOf('.stage') > 0 || name.indexOf('STAGE') > 0) {
      return 'stage'
    }
    if (id.indexOf('.e2e') > 0 || name.indexOf('E2E') > 0) {
      return 'e2e'
    }
    if (id.indexOf('.dev') > 0 || name.indexOf('DEV') > 0) {
      return 'dev'
    }
    return DEFAULT_ENV
  }
  return (
    Object.keys(configList).filter((k) =>
      configList[k].hostnames.includes(host)
    )[0] || DEFAULT_ENV
  )
}

export const getEnvSync = () => {
  return loadedEnv
}
