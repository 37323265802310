export function setItems(state, items) {
  state.items = items
}

export function resetItems(state) {
  state.items = []
}

export function setQuery(state, payload) {
  state.query = Object.assign({}, state.query, payload)
}

export function resetQuery(state) {
  state.query = {
    serialnumber: null,
    referencenumber: null,
    fulltext: null
  }
}

export function startSearching(state) {
  state.searching = true
}

export function stopSearching(state) {
  state.searching = false
}
