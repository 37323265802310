import qs from 'qs'
import { AxiosInstance } from 'axios'
import { IUserCarrier } from '~/carrier/user/IUserCarrier'
import { Carrier } from '~/carrier/Carrier'
import { GrantType } from '~/carrier/user/GrantType'
import { Method } from '~/carrier/Method'
import { StatusCode } from '~/carrier/StatusCode'

export interface ITokenResponse {
  accessToken: string | null
  refreshToken: string | null
  idToken: string | null
}

interface IAuthorizeData {
  // eslint-disable-next-line camelcase
  grant_type: GrantType
  code?: string
  // eslint-disable-next-line camelcase
  refresh_token?: string
}

export class UserCarrier extends Carrier implements IUserCarrier {
  private static AUTHORIZE_URL = 'authorize'
  constructor(api: AxiosInstance, private readonly jsonApi: AxiosInstance) {
    super(api, 'user')
  }

  public search(query: string) {
    return this.api.get('users', { params: { q: query } })
  }

  public get() {
    return this.jsonApi.get(this.path)
  }

  public put(data: object) {
    return this.jsonApi.put(this.path, data)
  }

  public delete() {
    return this.jsonApi.delete(this.path)
  }

  public logout(payload: object) {
    return this.jsonApi.post('log_out', payload)
  }

  public getAccessToken(code: string): Promise<ITokenResponse | false> {
    const data = { grant_type: GrantType.AuthorizationCode, code }
    return this.authorize(data)
  }

  public refreshAccessToken(
    refreshToken: string
  ): Promise<ITokenResponse | false> {
    const data = {
      grant_type: GrantType.RefreshToken,
      refresh_token: refreshToken
    }
    return this.authorize(data)
  }

  private async authorize(
    authorizeData: IAuthorizeData
  ): Promise<ITokenResponse | false> {
    try {
      const { status, data } = await this.api({
        method: Method.Post,
        url: UserCarrier.AUTHORIZE_URL,
        data: qs.stringify(authorizeData)
      })
      if (status === StatusCode.OK) {
        const accessToken = data?.access_token || null
        const refreshToken = data?.refresh_token || null
        const idToken = data?.id_token || null

        return { accessToken, refreshToken, idToken }
      }
    } catch (e) {
      // TODO: Add Error Handling
      return false
    }

    return false
  }
}
