import { IPostMemberDto } from '~/carrier/organizations/members/output/IPostMemberDto'
import { IPutMemberDto } from '~/carrier/organizations/members/output/IPutMemberDto'
import { IGetMemberDto } from '~/carrier/organizations/members/input/IGetMemberDto'
import { Role } from '~/models/user/Role'

export class Member {
  constructor(
    public readonly id = '',
    public readonly email = '',
    public role = Role.User,
    public readonly status = ''
  ) {}

  public static fromJson(dto: IGetMemberDto) {
    return new Member(dto.email, dto.email, dto.role, dto.status)
  }

  public static toPostDto(member: Member): IPostMemberDto {
    return member.toPostDto()
  }

  private toPostDto(): IPostMemberDto {
    return {
      email: this.email,
      role: this.role,
      status: this.status
    }
  }

  public toPutDto(): IPutMemberDto {
    return {
      role: this.role
    }
  }
}
