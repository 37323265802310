import { ActionHandler } from 'vuex'
import { IRootState } from '~/store'
import { FavoritesState } from '~/store/documents/favorites/state'
import { Favorite } from '~/models/document/Favorite'
import { Product } from '~/models/product/Product'
import { FavoritesStore } from '~/store/documents/favorites/local-store/FavoritesStore'
import Storage from '~/lib/storage'

export default {
  load({ getters, commit, dispatch }) {
    if (!getters.hasFavorites) {
      const favoritesStore = new FavoritesStore(Storage)

      commit('set', favoritesStore.favorites)
      return dispatch('products/load')
    }
  },
  toggle(
    { getters, dispatch },
    { favorite, product }: { favorite: Favorite; product: Product }
  ) {
    const isBookmarked = getters.isBookmarked(favorite)

    if (isBookmarked) {
      return dispatch('remove', favorite).then(() =>
        dispatch('products/remove', product)
      )
    }

    return dispatch('add', favorite).then(() =>
      dispatch('products/update', product)
    )
  },
  add({ commit, getters }, favorite: Favorite) {
    const favoritesStore = new FavoritesStore(Storage)

    commit('add', favorite)
    favoritesStore.save(getters.favorites)
  },
  remove({ commit, getters }, favorite: Favorite) {
    const favoritesStore = new FavoritesStore(Storage)

    commit('remove', favorite)
    favoritesStore.save(getters.favorites)
  }
} as { [handler: string]: ActionHandler<FavoritesState, IRootState> }
