import ORGANIZATION_THINGS from '~/queries/things.gql'

export default async function ({ route, redirect, store, app }) {
  if (route.query?.register === 'true') return // should redirect to register page

  if (!store.getters['user/isLoggedIn']) return

  if (route.name !== 'index') return

  if (
    store.getters['user/isServicePartner'] ||
    store.getters['user/isInternal']
  ) {
    const { data } = await app.apolloProvider?.defaultClient.query({
      query: ORGANIZATION_THINGS,
      fetchPolicy: 'cache-first'
    })
    if (!data?.orgData?.things?.length) {
      return redirect('/news')
    }
  }

  return redirect('/dashboard')
}
