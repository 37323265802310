import gql from 'graphql-tag'

const stateUpdater = ({ app }) => {
  // updates state of all things
  setInterval(() => {
    app.apolloProvider.clients.defaultClient.mutate({
      mutation: gql`
        mutation UpdateLocalThingState {
          updateThingsState @client
        }
      `
    })
  }, 20000)
}

export default stateUpdater
