import { SearchProductDto } from '~/carrier/products/input/SearchProductDto'
import Thing from '~/models/thing/Thing'

export class Product {
  constructor(
    public readonly ref = '',
    public readonly name = '',
    public readonly thumb = '',
    public readonly sn = '',
    public readonly deviceId = '',
    public readonly orgName = ''
  ) {}

  public static fromObject(dto: SearchProductDto): Product {
    return new Product(
      dto.ref,
      dto.name,
      dto.thumb,
      dto.sn,
      dto.deviceId,
      dto.orgName
    )
  }

  public static fromThing(thing: Thing): Product {
    return new Product(thing.model, thing.name, thing.image)
  }

  public is(product: Product): boolean {
    return product.ref === this.ref
  }

  public isNot(product: Product): boolean {
    return !this.is(product)
  }

  public get isDevice(): boolean {
    return !!this.deviceId
  }
}
