export function updateLocations({ commit, state }, payload) {
  const data = state.locations

  Object.keys(payload).forEach((id) => {
    if (id in state.locations) {
      data[id] = { ...state.locations[id], ...payload[id] }
      commit('updateLocation', { [id]: data[id] })
    }
  })
}

export function updateItems({ state }, payload) {
  state.dispatch('updateLocations', payload)
}

export function setByOrganization({ commit, rootGetters }) {
  commit('setLocations', rootGetters['organization/getLocations'])
}

export function selectLocation({ commit, rootGetters }, id) {
  commit('setLocation', rootGetters['organization/getById'](id))
}

export function clear({ commit }) {
  commit('setLocations', {})
  commit('clearLocation')
}
