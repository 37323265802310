import debounce from 'lodash-es/debounce'

import listToTree from '~/lib/listToTree'

export async function load({ dispatch, state, commit }) {
  // only load once for every 5s to avoid spamming backend
  const wasLoadedFiveSecondsAgo = state.lastLoad + 5000 > Date.now()
  if (wasLoadedFiveSecondsAgo) {
    return
  }

  commit('lastLoad', Date.now())
  try {
    const { data } = await this.$api.organization.get()

    return Promise.all([dispatch('update', data), dispatch('prune', data)])
  } catch (_) {}
}

export async function reload({ dispatch, commit }) {
  commit('clear')
  commit('lastLoad', Date.now())
  try {
    const { data } = await this.$api.organization.get()

    return dispatch('update', data)
  } catch (_) {}
}

/**
 * prune existing organizations that are not in the payload
 */
export const prune = ({ dispatch, state }, payload) => {
  const currentOrganizations = Object.keys(state.list)
  const updatedOrganizations = Object.keys(payload)

  currentOrganizations.forEach((org) => {
    if (updatedOrganizations.includes(org)) return
    dispatch('remove', org)
  })
}

const updateMultipleOrganizations = (dispatch, payload) => {
  const organizations = Object.values(payload)
  const updatedOrganizations = organizations.map(dispatch.bind(null, 'update'))
  return Promise.all(updatedOrganizations)
}

export function update({ dispatch, state }, payload) {
  const isPayloadValid = !!payload && typeof payload === 'object'
  if (!isPayloadValid) {
    return
  }

  const hasMultipleOrganizations = !payload.id
  const isNewOrganization = !state.list[payload.id]

  if (hasMultipleOrganizations) {
    return updateMultipleOrganizations(dispatch, payload)
  }

  if (isNewOrganization) {
    return dispatch('add', payload)
  }

  return dispatch('change', payload)
}

export function add({ commit, dispatch }, payload) {
  commit('add', payload)
  return dispatch('triggerTreeUpdate')
}

export function remove({ commit, dispatch, state }, org) {
  const orgid = typeof payload === 'object' ? org.id : org

  if (!orgid) {
    return false
  }
  if (state.list[orgid]) {
    commit('remove', orgid)
    if (orgid === state.selected) {
      commit('setSelected', null)
    }
    return dispatch('triggerTreeUpdate')
  }
}

export async function change({ commit, state, dispatch }, payload) {
  const hasParentOrganization = !!payload.porg
  const isNewParentOrganization = payload.porg !== state.list[payload.id].porg

  if (hasParentOrganization && isNewParentOrganization) {
    await dispatch('triggerTreeUpdate')
  }

  commit('merge', payload)
}

// TODO: Try to avoid throttle, this brings extra complexity
const throttleUpdateTree = debounce((dispatch) => dispatch('updateTree'), 100)

export function triggerTreeUpdate({ dispatch }) {
  throttleUpdateTree(dispatch)
}

export function updateTree({ dispatch, commit, state }) {
  const treeData = Object.keys(state.list).map((id) => {
    return { id, porg: state.list[id].porg || null }
  })
  commit('setTree', listToTree(treeData))
  return dispatch('selectOrg')
}

export async function requestAdd({ dispatch, commit, getters }, newOrg) {
  const { data } = await this.$api.organization.post(newOrg)

  return new Promise((resolve, reject) => {
    dispatch('update', data)
      .then(() => {
        /*
         * The 'updateTree' action will be called two times, this is the expected result,
         * because we want to wait after 'updateTree' is finished and will then call
         * 'setAdded' and 'setExpanded'.
         */
        dispatch('updateTree')
          .then(() => {
            commit('setAdded', data.id)
            commit('setExpanded', getters.getPathForOrg(data.id))
            setTimeout(() => commit('setAdded'), 1000)
            resolve()
          })
          .catch(reject)
      })
      .catch(reject)
  })
}

export async function requestUpdate({ dispatch }, newValues) {
  const { data } = await this.$api.organization.put(newValues)
  return dispatch('update', data)
}

export function requestRemove({ dispatch }, orgId) {
  return this.$api.organization
    .delete(orgId)
    .then(dispatch.bind(null, 'remove', orgId))
}

export function selectOrg({ commit, state }, id) {
  if (!id && state.selected) {
    return
  }

  if (!id && !state.selected) {
    // if no id is provided and nothing is selected, select the root org
    if (state.tree && state.tree[0]) {
      id = state.tree[0].id
    }
  }
  commit('setSelected', id)
}

export function clear({ commit }) {
  commit('clear')
}
