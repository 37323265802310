import { flatten } from 'lodash-es'
import { allowsTo } from '~/lib/allowsTo'

const handleRouteAccess = ({ store, error, route, $envConfig }) => {
  let routeConfig = []
  if (!$envConfig || !$envConfig.navigation) {
    return
  }
  Object.keys($envConfig.navigation).forEach((n) => {
    routeConfig = [
      ...routeConfig,
      ...flatten($envConfig.navigation[n]).filter((o) => {
        return (
          route.name &&
          (o.route === route.name.replace('-id', '') ||
            o.route?.name === route.name.replace('-id', ''))
        )
      })
    ]
  })

  // didn't found a config for current route
  if (routeConfig.length === 0) {
    return
  }

  // check if User is allowed
  const userCapabilities = store.getters['user/capabilities']
  const item = routeConfig[0]
  if (!allowsTo(item, userCapabilities)) {
    error({ statusCode: 403 })
  }
}

export default handleRouteAccess
