import { ICategoryDto } from '~/carrier/documents/input/IGetDocumentsDto'

export class Category {
  public id = ''
  public title = ''

  public static fromJson(dto: ICategoryDto) {
    const category = new Category()

    category.id = dto.id
    category.title = dto.title

    return category
  }
}
