import { GetterTree } from 'vuex'
import { IRootState } from '~/store'
import { INewsState } from '~/store/news/INewsState'
import { News } from '~/models/news/News'

export default {
  news: ({ news }) => news,
  byId: (_, { news }) => (newsId: string) =>
    news.find(({ id }: News) => id === newsId) || [],
  lastLoad: ({ lastLoad }) => lastLoad
} as GetterTree<INewsState, IRootState>
