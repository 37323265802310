import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=64fc692a"
import script from "./UserMenu.vue?vue&type=script&lang=js"
export * from "./UserMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesMembersAvatar: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/members/Avatar.vue').default,MoleculesDropdown: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Dropdown.vue').default})
