import Vue from 'vue'
import merge from 'lodash-es/merge'

export const setCurrentLanguage = (state, lang) => {
  state.currentLanguage = lang
}

export const mergeMessages = (state, { lang, messages }) => {
  state.messages = {
    ...state.messages,
    [lang]: merge(state.messages[lang], messages)
  }
}

export const addMissingTranslation = (state, { hash, data }) => {
  Vue.set(state.missingTranslations, hash, data)
}

export const setMissingTranslationSent = (state, hash) => {
  if (!state.sentTranslations.includes(hash)) {
    state.sentTranslations.push(hash)
  }
}

export const clearMissingTranslations = (state) => {
  state.missingTranslations = {}
}
