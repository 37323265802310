import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { GetServicePartnerDto } from './input/GetServicePartnerDto'
import { Carrier } from '~/carrier/Carrier'
import { ServicePartner } from '~/models/thing/ServicePartner'

export class ServicePartnerCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'servicepartner')
  }

  public search(query: string): Promise<ServicePartner[]> {
    const config = { params: { q: query } }
    return this.get(config)
  }

  private async get(config: AxiosRequestConfig): Promise<ServicePartner[]> {
    const { data = [] } = await this.api.get<GetServicePartnerDto[]>(
      this.path,
      config
    )

    return data.map(ServicePartner.fromObject)
  }
}
