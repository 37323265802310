
export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    statusCode() {
      return this.error?.statusCode || 500
    },
    message() {
      if (this.$te(`${this.statusCode}.message`)) {
        return this.$t(`${this.statusCode}.message`)
      }
      return this.error?.message
    }
  }
}
