import { OAuth2Client } from '@byteowls/capacitor-oauth2'
import { Browser } from '@capacitor/browser'
import {
  Auth,
  HTTPResponse,
  OpenIDConnectSchemeOptions,
  SchemePartialOptions
} from '@nuxtjs/auth-next'
import { OpenIDConnectScheme } from '~auth/runtime'

interface OIDCSchemeOptions extends OpenIDConnectSchemeOptions {
  clientId: string
  scope: string
}

function encodeQuery(queryObject: any) {
  return Object.entries(queryObject)
    .filter(([_key, value]) => typeof value !== 'undefined')
    .map(
      ([key, value]) =>
        encodeURIComponent(key) +
        // @ts-ignore
        (value != null ? '=' + encodeURIComponent(value) : '')
    )
    .join('&')
}

export default class OIDCScheme extends OpenIDConnectScheme {
  private readonly isNative: boolean
  constructor($auth: Auth, options: SchemePartialOptions<OIDCSchemeOptions>) {
    const mergedOptions: SchemePartialOptions<OIDCSchemeOptions> = {
      ...options,
      clientId: 'vsm',
      endpoints: {
        configuration: $auth.ctx.app.$envConfig.OPENID_CONFIG
      },
      responseType: 'code',
      grantType: 'authorization_code',
      scope: 'openid profile email',
      codeChallengeMethod: 'S256',
      acrValues: ''
    }

    super($auth, mergedOptions)
    this.isNative = $auth.ctx.$platform.isNative
  }

  async login(): Promise<void> {
    await this.$auth.ctx.$mixpanel?.trackLoginEvent()
    if (this.isNative) {
      return this._mobileLogin()
    }
    return super.login()
  }

  async logout() {
    const logoutEndpoint = this.options.endpoints.logout
    const idToken = this.idToken.get()
    if (this.isNative) {
      // close browser after page load on iOS
      Browser.addListener('browserPageLoaded', () => {
        Browser.close()
        Browser.removeAllListeners()
      })
      if (idToken !== false) {
        const opts = {
          id_token_hint: idToken,
          post_logout_redirect_uri: this.$auth.ctx.$envConfig.MOBILE_URI
        }
        await Browser.open({
          url: `${logoutEndpoint}?${encodeQuery(opts)}`
        })
      }
      this.$auth.reset()
      this.$auth.redirect('logout')
      return
    }
    if (logoutEndpoint && idToken !== false) {
      const opts = {
        id_token_hint: idToken,
        post_logout_redirect_uri: this.logoutRedirectURI
      }
      const url = `${logoutEndpoint}?${encodeQuery(opts)}`
      window.location.replace(url)
    } else {
      // otherwise the user will see a white blank page
      window.location.replace(this.logoutRedirectURI)
    }
    return this.$auth.reset()
  }

  private async _mobileLogin() {
    const {
      access_token_response: {
        access_token: token,
        refresh_token: refreshToken,
        id_token: idToken
      }
    } = await OAuth2Client.authenticate({
      appId: this.options.clientId as string,
      authorizationBaseUrl: this.options.endpoints.authorization,
      responseType: this.options.responseType,
      accessTokenEndpoint: this.options.endpoints.token,
      scope: this.options.scope as string,
      pkceEnabled: true,
      redirectUrl: this.$auth.ctx.$envConfig.MOBILE_URI
    })
    await this.$auth.setUserToken(token, refreshToken)
    this.idToken.set(idToken)
    this.$auth.ctx.store.dispatch('user/setToken', token)
    this.$auth.ctx.store.dispatch('user/setRefreshToken', refreshToken)
    this.$auth.redirect('home')
    return this.$auth.ctx.store.dispatch('user/loadSources')
  }

  setUserToken(token: string, refreshToken: string) {
    this.token.set(token)

    if (refreshToken) {
      this.refreshToken.set(refreshToken)
    }

    // Fetch user
    return this.$auth.fetchUser()
  }

  protected updateTokens(response: HTTPResponse): void {
    super.updateTokens(response)

    // when tokens get updated, pass it to user store
    const bearerToken = this.token.get()
    const refreshToken = this.refreshToken.get()
    if (typeof bearerToken !== 'string') return

    const token = bearerToken.split(' ')[1]
    this.$auth.ctx.store.dispatch('user/setToken', token)
    this.$auth.ctx.store.dispatch('user/setRefreshToken', refreshToken)
  }
}
