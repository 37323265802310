export default {
  namespaced: true,
  state: () => ({
    currentlyOpen: null
  }),
  actions: {
    open({ commit }, key) {
      commit('SET_CURRENTLY_OPEN', key)
    },
    close({ commit }) {
      commit('SET_CURRENTLY_OPEN', null)
    },
    openLangauge({ commit }) {
      commit('SET_CURRENTLY_OPEN', 'language')
    }
  },
  getters: {
    isOpen: (state) => (key) => {
      return state.currentlyOpen === key
    }
  },
  mutations: {
    SET_CURRENTLY_OPEN: (state, value) => {
      state.currentlyOpen = value
    }
  }
}
