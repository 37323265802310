import { MutationTree } from 'vuex'
import { DownloadsState } from '~/store/documents/downloads/state'
import { Download } from '~/models/document/Download'

export default {
  set(state, downloads) {
    state.downloads = downloads
  },
  add(state, download: Download) {
    state.downloads.push(download)
  },
  remove(state, download: Download) {
    state.downloads = state.downloads.filter((d) => download.isNot(d))
  }
} as MutationTree<DownloadsState>
