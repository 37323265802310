import * as Sentry from '@sentry/vue'
import { getRelease } from './helpers'
import { getEnvSync } from '~/plugins/config/loadEnvConfig'

export default ({ $envConfig, app: { router } }) => {
  const release = getRelease()

  if (release) {
    const env = getEnvSync()
    const isStaging = env === 'stage'
    const isProd = env === 'prod'
    const isStagingOrProd = isStaging || isProd
    Sentry.init({
      dsn: $envConfig.SENTRY_DSN,
      // enable tracing and replay on staging and prod
      integrations: isStagingOrProd
        ? [
            new Sentry.BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router),
              tracingOrigins: [...$envConfig.hostnames, /^\//]
            }),
            new Sentry.Replay()
          ]
        : [],
      // rename to staging to match sentry environment
      environment: isStaging ? 'staging' : env,
      release,
      sampleRate: 1,
      // Capture 1% of transactions for performance monitoring.
      tracesSampleRate: 0.01,
      // Capture Replay for 0% of all normal sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0
    })
  }
}
