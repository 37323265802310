import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'
import { IPostPrioServiceDto } from '~/carrier/prio-service/output/IPostPrioServiceDto'

export class PrioServiceCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'prio-service')
  }

  public post(prioService: IPostPrioServiceDto) {
    return this.api.post(this.path, prioService)
  }
}
