export class TaskScheduler {
  constructor() {
    this.id = null
  }

  schedule(task, delay) {
    const hasTask = typeof task === 'function'
    const hasDelay = typeof delay === 'number'
    if (!hasTask || !hasDelay) {
      return
    }

    this.id = setInterval(task, delay)
  }

  clear() {
    clearInterval(this.id)
    this.id = null
  }
}
