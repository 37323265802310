import { ActionHandler } from 'vuex'
import { IRootState } from '~/store'
import { ProductsState } from '~/store/documents/favorites/products/state'
import { Product } from '~/models/product/Product'
import Storage from '~/lib/storage'
import { ProductsStore } from '~/store/documents/favorites/products/local-store/ProductsStore'

export default {
  load({ commit }) {
    const productsStore = new ProductsStore(Storage)

    commit('set', productsStore.products)
  },
  async update({ dispatch }, product: Product) {
    await dispatch('remove', product)
    return dispatch('add', product)
  },
  add({ commit, getters }, product: Product) {
    if (!getters.has(product)) {
      const productsStore = new ProductsStore(Storage)

      commit('add', product)
      productsStore.save(getters.products)
    }
  },
  remove({ commit, getters }, product: Product) {
    if (!getters.hasFavorite(product)) {
      const productsStore = new ProductsStore(Storage)

      commit('remove', product)
      productsStore.save(getters.products)
    }
  }
} as { [handler: string]: ActionHandler<ProductsState, IRootState> }
