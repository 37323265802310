import { State } from '~/models/thing/State'

export const automaticOfflineIn = 1000 * 60 * 10

export const getState = (state, tsLastSeen) => {
  if (!state) {
    return State.Default
  }

  return Date.now() - automaticOfflineIn < parseInt(tsLastSeen)
    ? state.toLowerCase()
    : State.Default
}

export const stateChangeIn = (tsLastSeen) => {
  const diff = parseInt(tsLastSeen) + automaticOfflineIn - Date.now()
  return diff > 0 ? diff : false
}

export const getTimeout = (tsLastSeen, callback) => {
  const stateChange = stateChangeIn(tsLastSeen)
  return stateChange ? setTimeout(callback, stateChange) : null
}

export const getIsThingOnline = (localState) => {
  return localState !== State.Gray
}
