import { isString } from 'lodash-es'
import storage from '~/lib/storage'

const STORAGE_KEY = 'i18n-settings'

export const setLocale = ($i18n, locale) => {
  if (isString(locale)) {
    $i18n.setLocale(locale)
    storage.setItem(STORAGE_KEY, locale)
  }
}

export const getLocale = () => {
  return storage.getItem(STORAGE_KEY)
}
