import { ActionTree } from 'vuex'
import { IRootState } from '~/store'
import {
  IPromiseDialogState,
  PromiseDialogOptions
} from '~/store/promiseDialog/IPromiseDialogState'

export default {
  open({ commit }, options = {}) {
    const promiseDialogOptions: PromiseDialogOptions = {
      showCancelButton: true,
      ...options
    }
    commit('SET_OPEN', true)
    commit('SET_OPTIONS', promiseDialogOptions)
    const promise = new Promise((resolve) => {
      commit('SET_RESOLVE', resolve)
    })
    return promise
  },
  close({ commit }) {
    commit('SET_OPEN', false)
    commit('SET_OPTIONS', {})
    commit('SET_RESOLVE', null)
  },
  confirm({ dispatch, getters }) {
    getters.resolve(true)
    dispatch('close')
  },
  cancel({ dispatch, getters }) {
    getters.resolve(false)
    dispatch('close')
  }
} as ActionTree<IPromiseDialogState, IRootState>
