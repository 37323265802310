import jws from 'jws'

export default function getPayloadOfToken(token) {
  const jwtData = jws.decode(token)
  let payload = {}
  if (jwtData && jwtData.payload) {
    try {
      payload = JSON.parse(jwtData.payload)
    } catch (e) {
      payload = jwtData.payload
    }
  }
  return payload
}
