export class Interceptor {
  static TYPE = null

  onSuccess(payload) {
    return payload
  }

  onError(error) {
    return Promise.reject(error)
  }
}
