import constants from './constants'
import Storage from '~/lib/storage'

const contains = (field, data) => {
  return field.toLowerCase().includes(data.toLowerCase())
}

export function load({ commit, state }) {
  if (state.items === null || state.items.length <= 0) {
    if (Storage.hasItem(constants.NAMESPACE)) {
      const items = Storage.getItem(constants.NAMESPACE)
      commit('setItems', items)
    }
  }
}

export function loadByRef({ commit, state }, ref) {
  const entry = state.items.find((item) => item.ref === ref)
  if (entry) {
    // set as product for detail page
    const data = {}
    data[ref] = entry
    commit('products/updateItems', data, { root: true })
  }
}

export function add({ commit, getters }, item) {
  if (!getters.getByRef(item.ref)) {
    commit('addItem', item)
  }
}

export function search({ commit, state }, options) {
  commit('search/setQuery', options, { root: true })
  const myItems = []

  if (state.items !== null && state.items.length > 0) {
    state.items.forEach((item) => {
      // search for reference number
      if (options.referencenumber && item.ref) {
        if (item.ref === options.referencenumber) {
          myItems.push(item)
        } else {
          // cleanup referencenumber for regex; strip out all: +, -, /
          const cleanedStoredRef = item.ref.replace(/[-+/]/gm, '')
          // some QR Codes reference number have an "0" at the end; strip out last char in case of
          const cleanedGivenRef = options.referencenumber.substring(
            0,
            options.referencenumber.length - 1
          )
          if (
            cleanedStoredRef === options.referencenumber ||
            cleanedStoredRef === cleanedGivenRef
          ) {
            myItems.push(item)
          }
        }
      } else if (options.fulltext) {
        if (item.ref && contains(item.ref, options.fulltext)) {
          myItems.push(item)
        } else if (item.name && contains(item.name, options.fulltext)) {
          myItems.push(item)
        }
      }
    })
  }
  commit('search/setItems', myItems, { root: true })
  // set as product for detail page
  const data = {}
  myItems.forEach((item) => {
    data[item.ref] = item
  })
  commit('products/updateItems', data, { root: true })
}
