import ORG_THING_LIST from '~/queries/things.gql'
import THING_UPDATE_SUBSCRIPTION from '~/queries/things.subscription.gql'
import INBOX_MESSAGES_QUERY from '~/queries/inbox/messages.gql'
import INBOX_MESSAGE_SUBSCRIPTION from '~/queries/inbox/message.subscription.gql'
import { State } from '~/models/thing/State'
import { InboxMessage } from '~/models/inbox/Message'

const ERROR_STATES = [State.Red, State.Yellow]

export default {
  data() {
    return {
      things: [],
      thingAlerts: []
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['user/rorg']
    },
    userId() {
      return this.$store.getters['user/id']
    },
    thingAlertCount() {
      return this.thingAlerts.length
    },
    thingAlertColor() {
      if (this.thingAlerts.find((t) => t.localState === State.Red)) {
        return 'red'
      }
      if (this.thingAlerts.find((t) => t.localState === State.Yellow)) {
        return 'yellow'
      }
      return undefined
    },
    inboxAlerts() {
      if (!this.messages) return []
      return this.messages.filter(
        (message) => InboxMessage.fromObject(message).isPending
      )
    },
    inboxAlertCount() {
      return this.inboxAlerts.length
    }
  },
  apollo: {
    // query needs to be function to work around typescript bug in mixin unit test
    orgData() {
      return {
        query: ORG_THING_LIST,
        fetchPolicy: 'cache-first',
        result({ data = {} }) {
          this.things = data?.orgData?.things.slice() ?? []
          this.thingAlerts =
            this.things.filter((t) => {
              return ERROR_STATES.includes(t.localState) || t.maintenance_due
            }) ?? []
        }
      }
    },
    messages() {
      return {
        query: INBOX_MESSAGES_QUERY,
        fetchPolicy: 'cache-and-network',
        subscribeToMore: [
          {
            document: INBOX_MESSAGE_SUBSCRIPTION,
            updateQuery(
              { messages },
              { subscriptionData: { data: { message } = {} } = {} }
            ) {
              if (messages.find(({ id }) => message.id === id)) return
              return { messages: [message, ...messages] }
            }
          }
        ]
      }
    },
    $subscribe: {
      thingUpdated: {
        query: THING_UPDATE_SUBSCRIPTION,
        variables() {
          return {
            rorg: this.$store.getters['user/rorg']
          }
        },
        error() {},
        result({ data: { updatedThing = {} } = {} }) {
          const localState = updatedThing.localState || ''

          this.thingAlerts = this.thingAlerts.filter(
            ({ id }) => id !== updatedThing.id
          )

          if (
            ERROR_STATES.includes(localState) ||
            updatedThing.maintenance_due
          ) {
            this.thingAlerts.push({ ...updatedThing, localState })
          }
        }
      }
    }
  }
}
