import { Capacitor } from '@capacitor/core'
import openUrl from '~/lib/url/openUrl'

export default (url, target = '_blank') => {
  const urlOptions = {}
  if (Capacitor.getPlatform() === 'android') {
    urlOptions.hideurlbar = 'yes'
  } else if (Capacitor.getPlatform() === 'ios') {
    urlOptions.location = 'no'
  }
  return openUrl(url, target, urlOptions)
}
