import { get, uniqBy } from 'lodash-es'
import { Role } from '~/models/user/Role'

function mergeData(tree, list, flat = [], depth = 0) {
  tree.forEach((branch) => {
    const data = {
      ...branch,
      depth,
      ...list[branch.id],
      corg: mergeData(branch.corg, list, [], depth + 1)
    }
    flat = uniqBy([...flat, data], 'id')
  })
  return sortOrgs(flat)
}

const sortOrgs = (flat) => {
  // sort merged data by name and type
  return flat.sort((a, b) => {
    // first sort by type (regions before locations)
    if (orgHasGeoip(a) && !orgHasGeoip(b)) {
      return 1
    }
    if (!orgHasGeoip(a) && orgHasGeoip(b)) {
      return -1
    }
    // within same type, sort by name
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
      return 1
    }
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
      return -1
    }
    return 0
  })
}

const orgHasGeoip = (org) => {
  return org && org.geoip && org.geoip.latitude && org.geoip.longitude
}

/**
 * filters tree using the function provided
 * @param {object} tree
 * @param {function} filter
 * @returns {object} filteredTree
 */
const filterTree = (tree, filter) => {
  return tree.reduce((filteredTree, branch) => {
    const match = filter(branch)
    const childMatch = filterTree(branch.corg, filter)
    if (match || childMatch.length > 0) {
      filteredTree.push({
        ...branch,
        corg: childMatch
      })
    }
    return filteredTree
  }, [])
}

export const getList = (state) => JSON.parse(JSON.stringify(state.list))
export const getTree = (state, getters) => {
  const list = getters.getList
  const tree = JSON.parse(JSON.stringify(state.tree))
  return mergeData(tree, list)
}
export const getListExcept = (_state, getters) => (exceptId) => {
  const treeList = Object.values(getters.getList)
  const removeIds = getters.getAllChildrenIds(exceptId)
  return treeList.filter((branch) => {
    return removeIds.includes(branch.id) === false
  })
}
export const getListWithGeoIp = (_state, getters) => {
  const treeList = Object.values(getters.getList)
  return treeList.filter((branch) => {
    return getters.hasGeoip(branch.id)
  })
}
export const getTreeWithGeoIpByIds = (_state, getters) => (ids = []) => {
  const tree = getters.getTree
  const filterOrgWithGeoIp = (org) => {
    // check geoip
    if (!getters.hasGeoip(org.id)) {
      return false
    }
    // no id filter
    if (ids.length === 0) {
      return true
    }
    // check id
    return ids.includes(org.id)
  }
  const filteredTree = filterTree(tree, filterOrgWithGeoIp)
  return filteredTree
}

export const members = (_, { byId }) => (id) => {
  const members = get(byId(id), ['members'], {})
  return Object.values(members)
}

export const hasLastAdmin = (_, { members, rootId }) => {
  const admins = members(rootId).filter(
    (member) => member.role === Role.Admin && member.status === 'active'
  )
  return admins.length === 1
}

export const hasUsers = (_, { members, rootId }) => {
  const users = members(rootId).filter((member) =>
    [Role.User, Role.Technician].includes(member.role)
  )
  return users.length > 0
}
export const getExpanded = (state) => JSON.parse(JSON.stringify(state.expanded))
export const getAdded = (state) => JSON.parse(JSON.stringify(state.added))

export const byId = (state) => (id) => {
  if (state.list[id]) {
    return state.list[id]
  }
  return null
}

export const getPropertyOfOrg = (state) => (id, property) => {
  if (state.list[id] && state.list[id][property]) {
    return state.list[id][property]
  }
  return null
}

export const getSelectedId = (state) => {
  if (state.selected) {
    return state.selected
  }
  return null
}

export const getSelected = (state, getters) => {
  if (state.selected) {
    return getters.byId(state.selected)
  }
  return null
}

/**
 @return {boolean} true if there are geo coordinates on this org.
 */
export const hasGeoip = (state) => (id) => {
  return orgHasGeoip(state.list[id])
}

/**
 @return {array} all organizations with geo coordinates
 */
export const getOrgWithCoordinates = (state, getters) => {
  const locations = []
  Object.keys(state.list).forEach((id) => {
    if (getters.hasGeoip(id)) {
      locations.push(id)
    }
  })
  return locations
}

/**
 @return {boolean} true if organization has geoip and place id
 */
export const isValidOrg = (state) => (id) => {
  const org = state.list[id]
  return org && orgHasGeoip(org) && org.place_id
}

/**
 @return {boolean} true if there are more than one organization
 */
export const hasMoreThanOneOrg = (state) => {
  return Object.keys(state.list).length > 1
}

const isId = (id) => (organizationId) => organizationId === id
const filterId = (id) => (organizationId) => !isId(id)(organizationId)

export const hasChildren = (_, { getAllChildrenIds }) => (id) =>
  getAllChildrenIds(id).filter(filterId(id)).length > 0

export const isChild = (_, { getAllChildrenIds }) => (id, childId) =>
  !!getAllChildrenIds(id).filter(filterId(id)).find(isId(childId))

/**
 search for all children orgs of an org
 @return {array} uuids of corgs.
 */
export const getAllChildrenIds = (state) => (id) =>
  getChildElements(state.tree, id, false)

const getChildElements = (tree, searchId, add) => {
  let elements = []
  tree.forEach((t) => {
    let addThis = add
    if (t.id === searchId) {
      addThis = true
    }
    if (addThis) {
      elements = [...elements, t.id]
    }
    if (t.corg) {
      elements = [...elements, ...getChildElements(t.corg, searchId, addThis)]
    }
  })
  return elements
}

/**
 search for a specific org within the tree
 @return {object} tree object of specific org.
 */
export const getTreeElement = (state) => (id) => findTreeElement(state.tree, id)

const findTreeElement = (tree, searchId) => {
  let treeElement
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === searchId) {
      treeElement = JSON.parse(JSON.stringify(tree[i]))
      break
    } else if (tree[i].corg && tree[i].corg.length > 0) {
      treeElement = findTreeElement(tree[i].corg, searchId)
      if (treeElement) {
        break
      }
    }
  }
  return treeElement
}

/**
 generates the path through the tree to a specific org
 @return {array} all parents of searched org, starting with root org
 */
export const getPathForOrg = ({ tree }) => (orgId) => {
  const getPath = function (org, findId) {
    if (org.id === findId) {
      return [org.id]
    } else if (org.corg) {
      for (let i = 0; i < org.corg.length; i++) {
        const path = getPath(org.corg[i], findId)
        if (path !== null) {
          path.unshift(org.id)
          return path
        }
      }
    }
    return null
  }
  return getPath(tree[0] || [], orgId)
}

export const isRoot = (_, getters) => (id) => {
  const organization = getters.byId(id)
  const rorg = get(organization, 'rorg', null)

  return rorg === id
}

export const isRegion = (_, getters) => (id) => {
  return !getters.hasGeoip(id)
}

export const rootId = ({ tree }) => get(tree, ['0', 'id'], '')
