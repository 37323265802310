import { ComponentComposerPluginFactory } from '@duerrdental/component-composer'
import Vue from 'vue'
import i18nSettings from '~/plugins/i18n/settings'

const components = {
  text: { component: 'atoms-form-text-input' }
}
const factory = new ComponentComposerPluginFactory()
const locales = i18nSettings.locales.map(({ code }) => code)
const plugin = factory.create(components, locales)
Vue.use(plugin)
