import { GetterTree } from 'vuex'
import { IRootState } from '~/store'
import { IPromiseDialogState } from '~/store/promiseDialog/IPromiseDialogState'

export default {
  isOpen: (state) => {
    return state.open
  },
  options: (state) => {
    return state.options
  },
  resolve: (state) => {
    return state.resolve
  }
} as GetterTree<IPromiseDialogState, IRootState>
