import en from '~/lang/en.json'

export default () => {
  const supportedLanguages = [
    'da',
    'de',
    'en',
    'es',
    'fr',
    'it',
    'ko',
    'nl',
    'pl',
    'pt',
    'ru',
    'zh'
  ]
  if (window.Cypress) {
    supportedLanguages.push('xx')
  }
  return {
    currentLanguage: 'en',
    fallbackLanguage: 'en',
    supportedLanguages,
    messages: { en },
    missingTranslations: {},
    sentTranslations: []
  }
}
