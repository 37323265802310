import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { Brand } from './Brand'
import Storage from '~/lib/storage'

const BRANDING_STORE = 'branding'
export const DEFAULT_BRANDING = Brand.DuerrDental

export default async (branding) => {
  const isBrandingSetByGetParam = !!branding
  const isBrandingSetByLocalStorage = !!Storage.getItem(BRANDING_STORE)

  // on native apps determine branding by app id
  if (Capacitor.isNativePlatform()) {
    const { id } = await App.getInfo()
    if (id.indexOf('airtechniques') > 0) {
      return Brand.AirTechniques
    }
  }

  if (isBrandingSetByGetParam) {
    Storage.setItem(BRANDING_STORE, branding)
  } else if (isBrandingSetByLocalStorage) {
    branding = Storage.getItem(BRANDING_STORE)
  }
  return branding || DEFAULT_BRANDING
}
