import { ActionHandler } from 'vuex'
import { IRootState } from '~/store'
import { SubscriptionState } from '~/store/organization/subscriptions/state'
import { IPostSubscriptionDto } from '~/carrier/organizations/subscriptions/output/IPostSubscriptionDto'
import { Subscription } from '~/models/organization/subscription/Subscription'

export default {
  async load({ commit }, orgId) {
    const subscriptions = await this.$api.organization.subscriptions.get(orgId)

    commit('set', subscriptions)
  },
  async create({ commit, getters }, { orgId, endpoint, protocol }) {
    const subscriptions = getters.subscriptions || []
    const existingSubscription = subscriptions.find(
      (sub: Subscription) => sub.endpoint === endpoint
    )
    const existingSubscriptionPending =
      existingSubscription && existingSubscription.pending

    const dto: IPostSubscriptionDto = { endpoint, protocol }
    const subscription = await this.$api.organization.subscriptions.post(
      orgId,
      dto
    )
    if (!existingSubscriptionPending) commit('add', subscription)
  },
  async delete({ commit }, { orgId, id }) {
    await this.$api.organization.subscriptions.delete(orgId, id)
    commit('delete', id)
  }
} as { [handler: string]: ActionHandler<SubscriptionState, IRootState> }
