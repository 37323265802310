import { ActionHandler } from 'vuex'
import { IRootState } from '~/store'
import { IMembersState } from '~/store/organization/members/IMembersState'

export default {
  async create({ commit }, { organizationId, member }) {
    const api = this.$api.organization.members
    const members = await api.post(organizationId, [member])

    commit('addMembers', { organizationId, members })
  },
  async load({ commit }, organizationId) {
    const members = await this.$api.organization.members.get(organizationId)

    commit('addMembers', { organizationId, members })
  },
  async update({ commit, getters }, { organizationId, member }) {
    if (!getters.hasMember(organizationId)(member.id)) {
      throw new Error('cannot find member to edit')
    }

    await this.$api.organization.members.put(organizationId, member)

    commit('update', { organizationId, member })
  },
  async delete({ commit, getters }, { organizationId, id }) {
    if (!getters.hasMember(organizationId)(id)) {
      throw new Error('cannot find member to edit')
    }

    await this.$api.organization.members.delete(organizationId, id)

    commit('delete', { organizationId, id })
  }
} as { [handler: string]: ActionHandler<IMembersState, IRootState> }
