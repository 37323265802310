import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'

let lastCall = 0

export class StatesCarrier extends Carrier {
  constructor(api: AxiosInstance, protected readonly organizationPath: string) {
    super(api, 'states')
  }

  get(organizationIds: string[]) {
    // TODO: This code is copied from the dmv project,
    //  investigate if we really need this behaviour.
    const thisCall = Date.now()
    // do avoid multiple calls in a short timeframe, make this call max once per second
    if (thisCall > lastCall + 1000) {
      lastCall = thisCall
      return this.api.get('/organization/states', {
        params: { orgIds: organizationIds.join() }
      })
    }
    return new Promise((resolve) => resolve('GET States failed'))
  }
}
