
export default {
  props: {
    position: {
      type: String,
      default: 'top',
      validator(val) {
        return ['top', 'bottom'].includes(val)
      }
    },
    maxNotifications: {
      type: Number,
      default: 3
    },
    transitionGroupClasses: {
      type: Object,
      default: () => {
        return {
          enterActiveClassDelayed:
            'transform ease-out duration-300 transition delay-300',
          enterActiveClass: 'transform ease-out duration-300 transition',
          enterClass:
            'opacity-0 translate-y-2 md:translate-y-0 md:translate-x-4',
          enterToClass: 'translate-y-0 opacity-100 md:translate-x-0',
          leaveActiveClass:
            'transition transform ease-in-out duration-300 translate-y-2 md:translate-y-0 md:translate-x-4',
          leaveClass: 'opacity-100',
          leaveToClass: 'opacity-0',
          moveClass: 'transition duration-500'
        }
      }
    }
  },
  computed: {
    notifications() {
      return this.$store.getters['notify/notifications'] ?? []
    },
    sortedNotifications() {
      if (this.position === 'bottom') {
        return [...this.notifications].slice(0, this.maxNotifications)
      }
      // if not bottom reverse the array
      return [...this.notifications].reverse().slice(0, this.maxNotifications)
    }
  },
  render(createElement) {
    let klass =
      'fixed flex flex-col z-50 pointer-events-none right-0 w-screen h-screen items-center md:items-end py-12 md:py-20 px-4 lg:py-6 space-y-4 '

    klass += this.position === 'top' ? 'justify-start' : 'justify-end'
    return createElement(
      'transition-group',
      {
        attrs: {
          'enter-active-class':
            this.notifications.length > 1
              ? this.transitionGroupClasses.enterActiveClassDelayed
              : this.transitionGroupClasses.enterActiveClass,
          'enter-class': this.transitionGroupClasses.enterClass,
          'enter-to-class': this.transitionGroupClasses.enterToClass,
          'leave-active-class': this.transitionGroupClasses.leaveActiveClass,
          'leave-class': this.transitionGroupClasses.leaveClass,
          'leave-to-class': this.transitionGroupClasses.leaveToClass,
          'move-class': this.transitionGroupClasses.moveClass,
          tag: 'div',
          class: klass
        }
      },
      [
        Object.prototype.hasOwnProperty.call(this.$scopedSlots, 'default')
          ? this.$scopedSlots.default({
              notifications: this.sortedNotifications,
              close: (notification) =>
                this.$store.dispatch('notify/hide', notification)
            })
          : null
      ]
    )
  }
}
