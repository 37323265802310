import actions from '~/store/promiseDialog/actions'
import getters from '~/store/promiseDialog/getters'
import mutations from '~/store/promiseDialog/mutations'

export default {
  namespaced: true,
  state: () => ({
    open: false,
    options: {},
    resolve: null
  }),
  actions,
  getters,
  mutations
}
