export class Download {
  private static readonly DOWNLOAD_DIRECTORY = 'documents'
  public readonly path: string
  public readonly data: Blob

  constructor(fileName: string, data: Blob = new Blob()) {
    this.path = this.createPath(fileName)
    this.data = data
  }

  public is(download: Download): boolean {
    return download.path === this.path
  }

  public isNot(download: Download): boolean {
    return !this.is(download)
  }

  private createPath(fileName: string): string {
    return `${Download.DOWNLOAD_DIRECTORY}/${fileName}`
  }
}
