import { AxiosInstance } from 'axios'
import VueI18n from 'vue-i18n'
import { Carrier } from '~/carrier/Carrier'
import { News } from '~/models/news/News'

export class NewsCarrier extends Carrier {
  constructor(api: AxiosInstance, private readonly i18n: VueI18n) {
    super(api, 'news')
  }

  // we need to use this method, since we are loading VueI18n after the API
  // because we need the API on initalization of VueI18n
  public async get(lang: String): Promise<News[]> {
    const { data = [] } = await this.api.get(this.path, {
      params: { lang }
    })

    if (Array.isArray(data)) {
      return data.map(News.fromJson)
    }

    return []
  }

  private get configuration() {
    return { params: { lang: this.i18n.locale } }
  }
}
