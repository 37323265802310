import { ActionTree } from 'vuex'
import { IRootState } from '~/store'
import { INewsState } from '~/store/news/INewsState'

export default {
  async load({ commit, dispatch }) {
    const isLastLoadADayAgo = await dispatch('isLastLoadADayAgo')
    if (isLastLoadADayAgo) {
      const news = await this.$api.news.get(this.$i18n.locale)
      commit('setNews', news)
      commit('setLastLoad', new Date())
    }
  },
  isLastLoadADayAgo({ getters }) {
    const lastLoad = getters.lastLoad.getTime()
    const dayInMilliseconds = 1000 * 60 * 60 * 24

    return Date.now() > lastLoad + dayInMilliseconds
  },
  resetTimer({ commit, dispatch }) {
    commit('resetLastLoad')
    dispatch('load')
  }
} as ActionTree<INewsState, IRootState>
