export default {
  vueI18n: '~/plugins/i18n/vueI18n',
  lazy: true,
  langDir: 'lang/',
  defaultLocale: 'en',
  strategy: 'no_prefix',
  parsePages: false,
  detectBrowserLanguage: {
    /**
     * This avoids that a cookie is used for storing the
     * locale information.
     * We can not use a Cookie because of a known Capacitor
     * iOS Issue. It is currently not possible to store
     * a Cookie on iOS, see:
     * https://jira.duerrdental.com/browse/VSM-1445?focusedCommentId=20771&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-20771
     *
     * Further reading: https://i18n.nuxtjs.org/options-reference/#skipsettinglocaleonnavigate
     */
    useCookie: false
  },
  /**
   * This avoids that the locale will be set when navigating.
   * It must be set to true, because if we change the language
   * the i18n plugin would set the detected browser language
   * again on every navigation. Normally the i18n plugin uses
   * the cookie to set the language, if no cookie is used it
   * will always fall back to the detected browser language.
   *
   * Further reading: https://i18n.nuxtjs.org/options-reference/#skipsettinglocaleonnavigate
   */
  skipSettingLocaleOnNavigate: true,
  locales: [
    {
      code: 'da',
      name: 'Dansk',
      file: 'index.js'
    },
    {
      code: 'de',
      name: 'Deutsch',
      file: 'index.js'
    },
    {
      code: 'en',
      name: 'English',
      file: 'index.js'
    },
    {
      code: 'es',
      name: 'Español',
      file: 'index.js'
    },
    {
      code: 'fr',
      name: 'Français',
      file: 'index.js'
    },
    {
      code: 'it',
      name: 'Italiano',
      file: 'index.js'
    },
    {
      code: 'ko',
      name: '한국어',
      file: 'index.js'
    },
    {
      code: 'nl',
      name: 'Nederlands',
      file: 'index.js'
    },
    {
      code: 'pl',
      name: 'Polski',
      file: 'index.js'
    },
    {
      code: 'pt',
      name: 'Português',
      file: 'index.js'
    },
    {
      code: 'ru',
      name: 'Русский',
      file: 'index.js'
    },
    {
      code: 'zh',
      name: '中文',
      file: 'index.js'
    }
  ]
}
