import { Context } from '@nuxt/types/app'
import { HTTPResponse } from '@nuxtjs/auth-next'
import { isPageWhitelisted } from '~/lib/auth'

const handleAuth: (ctx: Context) => Promise<void | HTTPResponse> = async ({
  $auth,
  route,
  store,
  $platform
}) => {
  // should go through isLoggedIn for LC flow
  const hasRefreshToken = !!route.query.refresh_token
  if (hasRefreshToken) {
    return $auth.setStrategy('vsm')
  }

  // temporary switch between vsm and sso login
  const { sso } = route.query
  if (sso === 'true') {
    $auth.setStrategy('sso')
  } else if (sso === 'false') {
    $auth.setStrategy('vsm')
    store.dispatch('user/logout')
  }

  if ($auth.strategy.options.name !== 'sso') return

  if (route.path === '/login') return // after login, let nuxt auth get tokens

  try {
    const response = await $auth.refreshTokens()

    // redirect if refresh token not found
    if (!response && !isPageWhitelisted(route.name)) {
      // redirect without router only on website, otherwise app will have issues
      // noRouter is only needed as a workaround on website to handle invalid routes, eg: `/thing`
      const noRouter = !$platform.isNative
      // set redirect in session storage, in case user changes to vsm login
      sessionStorage.setItem('redirectFrom', route.fullPath)
      $auth.redirect('login', noRouter)
      return
    }
  } catch (e) {
    return store.dispatch('user/logout')
  }

  if ($auth.loggedIn) await store.dispatch('user/loadSources')
}

export default handleAuth
