import { IStoredDocument } from '~/store/documents/favorites/local-store/models/stored-favorite/stored-document/IStoredDocument'
import { Document } from '~/models/document/Document'

export class StoredDocument {
  public static toDocument(storedDocument: IStoredDocument): Document {
    return new Document(
      storedDocument.id,
      storedDocument.name,
      storedDocument.category,
      storedDocument.lastChange,
      storedDocument.fileExtension,
      storedDocument.fileName,
      storedDocument.language,
      storedDocument.fileSize,
      storedDocument.teaser,
      storedDocument.text,
      storedDocument.thumbnailUrl,
      storedDocument.url,
      storedDocument.isStream
    )
  }
}
