import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'

export class EtkCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'etk')
  }

  public get(referenceId = null, language = null) {
    const params = EtkCarrier.createParameters(referenceId, language)
    return this.api.get(this.path, { params })
  }

  private static createParameters(
    referenceId: string | null,
    language: string | null
  ) {
    const parameters: { refid?: string; lang?: string } = {}

    if (referenceId !== null) {
      parameters.refid = referenceId
    }

    if (language !== null) {
      parameters.lang = language
    }

    return parameters
  }
}
