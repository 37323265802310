export const notificationIconMap = {
  error: {
    icon: 'solid/exclamation-circle',
    color: 'text-red-700'
  },
  warning: {
    icon: 'solid/exclamation',
    color: 'text-yellow-600'
  },
  info: {
    icon: 'information-circle',
    color: 'text-blue-700'
  },
  success: {
    icon: 'check-circle',
    color: 'text-green-700'
  },
  dialog: {
    icon: 'check-circle',
    color: 'text-green-700'
  }
}
