/* eslint-disable camelcase */
import Thing from '~/models/thing/Thing'

export type RegisteredProductDto = {
  id: string
  name: string
  ref: string
  sn: string
  image: string
  orgName: string
  registeredAt: string
}

export class RegisteredProduct {
  constructor(
    public readonly id: string,
    public readonly ref: string = '',
    public readonly sn: string = '',
    public readonly name: string = '',
    public readonly image: string = '',
    public readonly orgName: string = '',
    public readonly isPending: boolean = false,
    public readonly registeredAt: Date = new Date()
  ) {}

  public static fromThing(thing: Thing): RegisteredProduct {
    return new RegisteredProduct(
      thing.id,
      thing.model,
      thing.sn,
      thing.name,
      thing.image,
      thing.orgName,
      thing.isPending,
      new Date(parseInt(thing.ts_last_seen))
    )
  }

  public static fromObject(dto: RegisteredProductDto): RegisteredProduct {
    return new RegisteredProduct(
      dto.id,
      dto.ref,
      dto.sn,
      dto.name,
      dto.image,
      dto.orgName,
      false,
      new Date(dto.registeredAt)
    )
  }
}
