/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */
import { Plugin } from '@nuxt/types'
import mixpanel, {
  OverridedMixpanel,
  RequestOptions,
  Callback
} from 'mixpanel-browser'
import {
  MixpanelEventName,
  MixpanelEventProperties,
  NavigateEventProperties
} from './event'

export interface IMixpanel extends OverridedMixpanel {
  track(
    eventName: MixpanelEventName,
    properties?: MixpanelEventProperties,
    optionsOrCallback?: RequestOptions,
    callback?: Callback
  ): Promise<void>
  trackNavigateEvent(properties: NavigateEventProperties): void
  trackLoginEvent(): Promise<void>
}

const MixpanelPlugin: Plugin = ({ app, store }, inject) => {
  const isProduction = app.$envConfig.TYPE === 'production'
  const token = app.$envConfig.MIXPANEL_TOKEN

  if (!isProduction || !token) return

  mixpanel.init(token)

  /**
   * Wrapper function to promisify mixpanel.track
   */
  const track = (
    eventName: MixpanelEventName,
    properties?: MixpanelEventProperties,
    options?: RequestOptions,
    callback?: Callback
  ): Promise<void> => {
    return new Promise((resolve) => {
      mixpanel.track(eventName, properties, options, (response) => {
        callback?.(response)
        resolve()
      })
    })
  }

  /**
   * send route event only when user is logged in
   */
  const trackNavigateEvent = (properties: NavigateEventProperties): void => {
    if (!store.getters['user/isLoggedIn']) return
    track('Navigate', properties)
  }

  /**
   * send login event immediately
   */
  const trackLoginEvent = (): Promise<void> =>
    track('Login', undefined, { send_immediately: true })

  inject('mixpanel', {
    ...mixpanel,
    track,
    trackNavigateEvent,
    trackLoginEvent
  })
}

export default MixpanelPlugin
