import Vue from 'vue'
import { mergeWith } from 'lodash-es'

import defaultState from './state'

export function add(state, org) {
  Vue.set(state.list, org.id, org)
}

export function merge(state, org) {
  const newOrg = mergeWith(state.list[org.id], org, fnMerge)
  Vue.set(state, 'list', { ...state.list, [org.id]: newOrg })
}

export function remove(state, orgid) {
  Vue.delete(state.list, orgid)
}

export function treeUpdate(state, treeUpdate) {
  Vue.set(state, 'createTree', treeUpdate)
}

export function setTree(state, tree) {
  Vue.set(state, 'tree', tree)
}

export function setExpanded(state, expanded) {
  Vue.set(state, 'expanded', expanded)
}

export function setAdded(state, added) {
  Vue.set(state, 'added', added || null)
}

export function setSelected(state, orgId) {
  Vue.set(state, 'selected', orgId)
}

export function clear(state) {
  Object.assign(state, defaultState())
}

export function lastLoad(state, lastLoad) {
  Vue.set(state, 'lastLoad', lastLoad)
}

const fnMerge = (a, b) => {
  return Array.isArray(a) || typeof a === 'object' ? b : undefined
}
