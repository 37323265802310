import { stringify } from 'qs'
import { Store } from 'vuex'
import { Route } from 'vue-router'
import { Capacitor } from '@capacitor/core'
import { NuxtConfig } from '@nuxt/types'
import getLoginState from '~/lib/getLoginState'
import ServiceApp from '~/lib/native/login'
import openUrl from '~/lib/url/openUrl'

export class LoginService {
  private config: NuxtConfig
  private store: Store<any>
  private route: Route
  private redirect: any

  constructor(
    config: NuxtConfig,
    store: Store<any>,
    route: Route,
    redirect: any
  ) {
    this.config = config
    this.store = store
    this.route = route
    this.redirect = redirect
  }

  public get urls(): { [key: string]: string } {
    return Object.freeze({
      login: '/login',
      register: '/register',
      thanks: '/thanks'
    })
  }

  public getUrl(url?: string) {
    const branding = this.config.branding
    let baseUri = this.config[`LOGIN_URI_${branding}`]
    if (!baseUri) {
      baseUri = this.config.LOGIN_URI
    }
    return baseUri + (this.urls[url as string] || '')
  }

  public getQueryString() {
    let clientId = 'vsm'
    let redirectUri =
      window.location.protocol + '//' + window.location.host + '/'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { register, code, ...q } = this.route.query

    if (Capacitor.isNativePlatform()) {
      clientId = 'serviceApp'
      redirectUri = this.getUrl('thanks')
    }

    return stringify({
      ...q,
      response_type: 'code',
      client_id: clientId,
      brand: this.config.branding,
      redirect_uri: redirectUri,
      state: getLoginState()
    })
  }

  public get loginUrl() {
    return this.getUrl('login') + '?' + this.getQueryString()
  }

  public get registerUrl() {
    return this.getUrl('register') + '?' + this.getQueryString()
  }

  public get baseUrl() {
    return this.getUrl()
  }

  public loginMobile(target = '_blank') {
    return ServiceApp.handleInAppBrowserResponse(
      this.redirect,
      'login',
      this.store,
      openUrl(this.loginUrl, target, {
        toolbarcolor: '#ffffff',
        closebuttoncolor: '#ffffff',
        hidenavigationbuttons: 'yes',
        location: 'no',
        footer: 'no',
        hideurlbar: 'yes',
        toolbartranslucent: 'yes',
        hardwareback: 'no'
      })
    )
  }
}
