import { AppApiFactory } from '~/plugins/api/app-api-factory'
import { InterceptorFactory } from '~/plugins/api/api-factory/interceptors/interceptor-factory'
import { TokenInterceptor } from '~/plugins/api/api-factory/interceptors/request/token-interceptor'
import { InvalidApiKeyInterceptor } from '~/plugins/api/api-factory/interceptors/response/invalid-api-key-interceptor'
import { TimeoutErrorInterceptor } from '~/plugins/api/api-factory/interceptors/response/timeout-error-interceptor'

export class AuthJsonApiFactory extends AppApiFactory {
  get _headers() {
    return {
      'x-api-key': this._apiKey,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }

  get _baseUrl() {
    return this._config.API_AUTH
  }

  _addInterceptors(api) {
    const interceptorFactory = new InterceptorFactory(api)

    return interceptorFactory.create([
      new TokenInterceptor(this._store),
      new InvalidApiKeyInterceptor(this._store),
      new TimeoutErrorInterceptor(this._store, this._i18n)
    ])
  }
}
