import { isEmpty, isString } from 'lodash-es'
import { IStorage } from './IStorage'

const storage: IStorage = {
  setItem: (key: string, value: any) =>
    localStorage.setItem(key, encode(value)),
  getItem: (key: string) => {
    const item = localStorage.getItem(key)
    return item ? decode(item) : null
  },
  removeItem: (key: string) => localStorage.removeItem(key),
  hasItem: (key: string) => !isEmpty(localStorage.getItem(key)),
  clear: () => localStorage.clear()
}

export default storage

/**
 * The stored Value Prefix identifies values in the LocalStore
 * which are stored by VSM.
 * @type {string}
 */
const STORED_VALUE_PREFIX = '__vsm_'
const VALUE_TYPE_SEPARATOR = '|'

const createKey = (key: string) => `${STORED_VALUE_PREFIX}${key}`

const createStoredValue = (key: string, value: any) =>
  `${createKey(key)}${VALUE_TYPE_SEPARATOR}${value}`

/**
 * Must have all the same length
 * @type {{DATE: string, NUMBER: string, REG_EX: string, STRING: string, OBJECT: string, FUNCTION: string, BOOLEAN: string}}
 */
const types = {
  DATE: 'date',
  REG_EX: 'expr',
  NUMBER: 'numb',
  BOOLEAN: 'bool',
  STRING: 'strn',
  FUNCTION: 'strn',
  OBJECT: 'objt'
}

// Copied from Quasar
// @see: https://github.com/quasarframework/quasar/blob/master/ui/src/utils/web-storage.js
function encode(value: any) {
  if (Object.prototype.toString.call(value) === '[object Date]') {
    return createStoredValue(types.DATE, value.toUTCString())
  }
  if (Object.prototype.toString.call(value) === '[object RegExp]') {
    return createStoredValue(types.REG_EX, value.source)
  }
  if (typeof value === 'number') {
    return createStoredValue(types.NUMBER, value)
  }
  if (typeof value === 'boolean') {
    return createStoredValue(types.BOOLEAN, value ? '1' : '0')
  }
  if (typeof value === 'string') {
    return createStoredValue(types.STRING, value)
  }
  if (typeof value === 'function') {
    return createStoredValue(types.FUNCTION, value.toString())
  }
  if (value === Object(value)) {
    return createStoredValue(types.OBJECT, JSON.stringify(value))
  }

  return value
}

function decode(value: any) {
  const isStoredValue = new RegExp(`^${STORED_VALUE_PREFIX}`)

  if (!isString(value) || !isStoredValue.test(value)) {
    return value
  }

  const identifierLength = createKey(types.OBJECT).length
  const type = value.substr(0, identifierLength)
  const source = value.substring(identifierLength + 1)

  switch (type) {
    case createKey(types.DATE):
      return new Date(source)

    case createKey(types.REG_EX):
      return new RegExp(source)

    case createKey(types.NUMBER):
      return Number(source)

    case createKey(types.BOOLEAN):
      return Boolean(source === '1')

    case createKey(types.STRING):
      return '' + source

    case createKey(types.OBJECT):
      return JSON.parse(source)

    default:
      return value
  }
}
