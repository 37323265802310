import { Device } from '@capacitor/device'
import {
  BarcodeFormat,
  BarcodeScanner,
  GoogleBarcodeScannerModuleInstallState
} from '@capacitor-mlkit/barcode-scanning'
import type {
  Barcode,
  CameraPermissionState
} from '@capacitor-mlkit/barcode-scanning'
import VueI18n from 'vue-i18n'

type ScannerApiResult = {
  barcode: String
}

const BARCODE_FORMATS: BarcodeFormat[] = [
  BarcodeFormat.DataMatrix,
  BarcodeFormat.QrCode,
  BarcodeFormat.Code39
]

export class ScannerApi {
  private isModuleAvailable: boolean = false
  private _isScanning: boolean = false
  constructor(private readonly i18n: VueI18n) {
    this.checkAndInstallModule()
  }

  public get isScanning(): boolean {
    return this._isScanning
  }

  public async scan(): Promise<ScannerApiResult> {
    const response: ScannerApiResult = {
      barcode: ''
    }

    if (!(await this.checkAndRequestPermissions())) return response

    try {
      const result = await this.startScan()
      response.barcode = result.rawValue
      return response
    } catch (_) {
      return response
    }
  }

  private async startScan(): Promise<Barcode> {
    // google barcode scanner
    if (this.isModuleAvailable) {
      const result = await BarcodeScanner.scan({
        formats: BARCODE_FORMATS
      })
      return result.barcodes[0]
    }
    // barebones scanner for devices without Google Play services
    return new Promise((resolve) => {
      BarcodeScanner.addListener('barcodeScanned', (result) => {
        this.stopScan()
        resolve(result.barcode)
      })
      BarcodeScanner.startScan({
        formats: BARCODE_FORMATS
      })
      document.querySelector('html')?.classList.add('hidden')
      this._isScanning = true
    })
  }

  public async stopScan(): Promise<void> {
    document.querySelector('html')?.classList.remove('hidden')

    await BarcodeScanner.removeAllListeners()
    await BarcodeScanner.stopScan()
    this._isScanning = false
  }

  /**
   * check if Google code scanner API is available, and install if necessary
   */
  private async checkAndInstallModule(): Promise<void> {
    const isAndroid = (await Device.getInfo()).platform === 'android'

    if (isAndroid) {
      const scannerModule = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()
      if (!scannerModule.available) {
        return this.installModule()
      }
    }
    this.isModuleAvailable = true
  }

  private async installModule(): Promise<void> {
    BarcodeScanner.addListener(
      'googleBarcodeScannerModuleInstallProgress',
      (e) => {
        if (e.state !== GoogleBarcodeScannerModuleInstallState.COMPLETED) return
        this.isModuleAvailable = true
      }
    )
    await BarcodeScanner.installGoogleBarcodeScannerModule()
  }

  private async checkAndRequestPermissions(): Promise<Boolean> {
    const status = await this.checkPermissions()

    if (status === 'granted') {
      // user granted permission
      return true
    }

    if (status === 'prompt') {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const c = confirm(this.i18n.tc('search.scan_permission'))
      if (!c) {
        return false
      }
    }

    if (status === 'denied') {
      // the user denied permission for good
      // redirect user to app settings if they want to grant it anyway
      const c = confirm(this.i18n.tc('search.scan_permission_settings'))
      if (c) {
        BarcodeScanner.openSettings()
      }
      return false
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const requestStatus = await BarcodeScanner.requestPermissions()
    return requestStatus.camera === 'granted'
  }

  private async checkPermissions(): Promise<CameraPermissionState> {
    const permissions = await BarcodeScanner.checkPermissions()
    return permissions.camera
  }
}
