import { sortBy } from 'lodash-es'
import { GetterTree } from 'vuex'
import { IRootState } from '~/store'
import { IMembersState } from '~/store/organization/members/IMembersState'
import { Member } from '~/models/organization/member/Member'

export default {
  members: ({ members }) => (organizationId: string) => {
    if (members[organizationId]) {
      const sortByEmail = (member: Member) => member.email.toLowerCase()
      return sortBy(Object.values(members[organizationId]), [sortByEmail])
    }
    return []
  },
  hasMembers: ({ members }) => (organizationId: string) =>
    Object.keys(members[organizationId] || {}).length > 0,
  hasMember: ({ members }, { hasMembers }) => (organizationId: string) => (
    id: string
  ) => (hasMembers(organizationId) ? !!members[organizationId][id] : false)
} as GetterTree<IMembersState, IRootState>
