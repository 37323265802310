import { StoredFavorite } from './models/stored-favorite/StoredFavorite'
import { IStorage } from '~/lib/IStorage'
import { Favorite } from '~/models/document/Favorite'

// TODO: Currently the Favorites are not loaded user dependent, should we change this?
export class FavoritesStore {
  private static readonly STORE_KEY: string = 'documents/favorites'

  constructor(private readonly store: IStorage) {}

  public save(favorites: Favorite[]) {
    this.store.setItem(FavoritesStore.STORE_KEY, favorites)
  }

  public get isEmpty(): boolean {
    return !this.hasFavorites
  }

  public get favorites(): Favorite[] {
    return [...this._favorites]
  }

  private get hasFavorites(): boolean {
    return this.store.hasItem(FavoritesStore.STORE_KEY)
  }

  private get _favorites(): Favorite[] {
    if (this.hasFavorites) {
      return this.store
        .getItem(FavoritesStore.STORE_KEY)
        .map(StoredFavorite.toFavorite)
    }

    return []
  }
}
