export function addDebug({ commit }, text) {
  commit('addMessage', { severity: 'debug', text })
}

export function addInfo({ commit }, text) {
  commit('addMessage', { severity: 'info', text })
}

export function addError({ commit }, text) {
  commit('addMessage', { severity: 'error', text })
}
