import constants from './constants'

const defaultParameters = {
  position: 'top',
  timeout: constants.TIMEOUT
}

function getParameters(payload, type) {
  if (typeof payload === 'string') {
    payload = {
      message: payload
    }
  }
  payload.type = type

  return Object.assign({}, defaultParameters, payload)
}

export function error({ dispatch }, payload) {
  dispatch('show', getParameters(payload, constants.ERROR))
}

export function warning({ dispatch }, payload) {
  dispatch('show', getParameters(payload, constants.WARNING))
}

export function info({ dispatch }, payload) {
  dispatch('show', getParameters(payload, constants.INFO))
}

export function success({ dispatch }, payload) {
  dispatch('show', getParameters(payload, constants.SUCCESS))
}

export function dialog({ dispatch }, payload) {
  if (typeof payload === 'string') {
    payload = {
      message: payload
    }
  }
  payload.isDialog = true
  dispatch('show', getParameters(payload, payload.type || constants.DIALOG))
}

export function show({ state, commit, dispatch }, payload) {
  if (!payload.id) {
    payload.id = (payload.title || '-') + (payload.message || '-')
  }
  // check if message is already displayed
  if (
    state.displayedMessages.filter(
      (m) => m.id === payload.id && m.type === payload.type
    ).length === 0
  ) {
    // message is not displayed yet, show it:
    commit('addMessage', payload)
    setTimeout(() => dispatch('hide', payload), payload.timeout)
  }
}

export function hide({ commit }, payload) {
  if (typeof payload === 'string') {
    payload = {
      message: payload
    }
  }
  if (!payload.id) {
    payload.id = (payload.title || '-') + (payload.message || '-')
  }
  commit('removeMessage', payload)
}
