export async function search({ commit, dispatch }, options) {
  commit('startSearching')
  commit('setQuery', options)

  const query = options?.fulltext?.trim()
  const locale = this.$i18n.locale

  try {
    const products = await this.$api.products.search(locale, query)
    const hasProducts = products.length > 0

    if (hasProducts) {
      commit('setItems', products)
      const items = products.reduce(
        (result, item) => ({ ...result, [item.ref]: item }),
        {}
      )
      commit('products/updateItems', items, { root: true })
    } else {
      commit('resetItems')
    }
  } catch (err) {
    dispatch('notify/error', err && err.message, { root: true })
    commit('resetItems')
  } finally {
    commit('stopSearching')
  }
}

export function clearSearch({ commit }) {
  commit('resetItems')
  commit('resetQuery')
}
