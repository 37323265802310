import { MutationTree } from 'vuex'
import { Product } from '~/models/product/Product'
import { ProductsState } from '~/store/documents/favorites/products/state'

export default {
  set(state, products) {
    state.products = products
  },
  add(state, product: Product) {
    state.products.push(product)
  },
  remove(state, product: Product) {
    if (!state.products.length) return

    state.products = state.products.filter(product.isNot.bind(product))
  }
} as MutationTree<ProductsState>
