export const get = (state) => {
  return state.location
}

export const getFloors = (state) => {
  if (state.location && state.location.floors) {
    return state.location.floors
  }
  return null
}

export const getById = (state) => (id) => {
  if (state.locations[id]) {
    return state.locations[id]
  }
  return {}
}

export const getAll = (state) => {
  return state.locations
}

export const changed = (state) => {
  return state.changed
}

export const getCount = (state) => {
  return Object.keys(state.locations).length
}
