import objectHash from 'object-hash'
import { get } from 'lodash-es'

import constants from './constants'
import Storage from '~/lib/storage'

const Connection = {
  NONE: 'none'
}

export default {
  setup({ commit, dispatch, getters }) {
    if (getters.isSetup) return

    dispatch('process') // process existing queue on setup

    // TODO: Investigate if this could be implemented with apollo
    if (this.$platform.isNative) {
      const type = get(navigator, ['connection', 'type'], '')
      const isConnected = () => type !== Connection.NONE

      return document.addEventListener('deviceready', () => {
        commit('online', isConnected())
        document.addEventListener(
          'online',
          () => {
            commit('online', isConnected())
            dispatch('process')
          },
          false
        )
        document.addEventListener(
          'offline',
          () => {
            commit('online', isConnected())
          },
          false
        )
      })
    }
    commit('online', navigator.onLine)
    window.addEventListener('online', () => {
      commit('online', navigator.onLine)
      dispatch('process')
    })
    window.addEventListener('offline', () => {
      commit('online', navigator.onLine)
    })

    commit('setIsSetup', true)
  },
  /**
   * add item to queue list
   */
  add(_, data) {
    if (!data.action || !data.payload) {
      return
    }

    // create a hash from given data
    const hash = objectHash.sha1({
      action: data.action,
      payload: data.payload
    })

    let exists = false

    // add item
    let items = {}
    if (Storage.hasItem(constants.NAMESPACE)) {
      items = Storage.getItem(constants.NAMESPACE)
      if (items[hash]) {
        exists = true
      }
    }
    if (!exists) {
      items[hash] = data
      Storage.setItem(constants.NAMESPACE, items)
    }
  },
  process({ dispatch, state }) {
    // don't process queue, when we are not online
    if (!state.online) {
      return
    }
    if (Storage.hasItem(constants.NAMESPACE)) {
      const items = Storage.getItem(constants.NAMESPACE)
      Object.keys(items).forEach((key) => {
        const { action, payload } = items[key]
        dispatch('removeItemByHash', key).then(() =>
          dispatch(action, payload, { root: true })
        )
      })
    }
  },
  removeItemByHash(_, hash) {
    if (Storage.hasItem(constants.NAMESPACE) && hash) {
      const items = Storage.getItem(constants.NAMESPACE)
      delete items[hash]
      Storage.setItem(constants.NAMESPACE, items)
    }
  }
}
