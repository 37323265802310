import constants from './constants'
import Storage from '~/lib/storage'

export function items() {
  let items = {}
  if (Storage.hasItem(constants.NAMESPACE)) {
    items = Storage.getItem(constants.NAMESPACE)
  }
  return items
}

export const isOnline = (state) => state.online

export const isSetup = ({ isSetup }) => isSetup
