import { TaskScheduler } from '~/lib/task-scheduler'
import { ActionScheduler } from '~/store/plugins/action-scheduler'

const create = (modules) => {
  const taskScheduler = new TaskScheduler()
  const actionScheduler = new ActionScheduler(taskScheduler, modules)

  return [actionScheduler.create.bind(actionScheduler)]
}

export default { create }
