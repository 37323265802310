export const getCurrentLanguage = (state) => state.currentLanguage

export const getFallbackLanguage = (state) => state.fallbackLanguage

export const getMessages = (state) => state.messages

export const getMessagesOfLanguage = (state) => (lang) =>
  state.messages[lang] || {}

export const getSupportedLanguages = (state) => state.supportedLanguages

export const isLangSupported = (state) => (lang) =>
  state.supportedLanguages.includes(lang)

export const getUserLanguage = (state) => {
  const userLang = (
    window.navigator.language ||
    window.navigator.userLanguage ||
    state.fallbackLanguage
  ).split('-')[0]
  return state.supportedLanguages.includes(userLang)
    ? userLang
    : state.fallbackLanguage
}
