import { MutationTree } from 'vuex'
import { INewsState } from '~/store/news/INewsState'

export default {
  setNews(state, news) {
    state.news = news
  },
  setLastLoad(state, lastLoad) {
    state.lastLoad = lastLoad
  },
  resetLastLoad(state) {
    state.lastLoad = new Date(0)
  }
} as MutationTree<INewsState>
