import { MutationTree } from 'vuex'
import { IMembersState } from '~/store/organization/members/IMembersState'
import { Member } from '~/models/organization/member/Member'

export default {
  addMembers(state, { organizationId, members }) {
    const organizationMembers = {
      ...state.members[organizationId],
      ...members.reduce(
        (result: { [id: string]: Member }, member: Member) => ({
          ...result,
          [member.id]: member
        }),
        {}
      )
    }

    state.members = {
      ...state.members,
      [organizationId]: organizationMembers
    }
  },
  update(state, { organizationId, member }) {
    const exists = !!state.members[organizationId][member.id]

    if (exists) {
      state.members[organizationId] = {
        ...state.members[organizationId],
        [member.id]: {
          ...state.members[organizationId][member.id],
          ...member
        }
      }
    }
  },
  delete(state, { organizationId, id }) {
    const organizationMembers = { ...state.members[organizationId] }
    delete organizationMembers[id]
    state.members[organizationId] = organizationMembers
  }
} as MutationTree<IMembersState>
