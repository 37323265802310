import URL from '~/plugins/url'

const handlers = {
  login: (params, redirect, store, inAppBrowserRef) => {
    return new Promise((resolve) => {
      inAppBrowserRef.addEventListener('exit', () => resolve(false))
      if (params.url) {
        const url = URL.parse(params.url)
        const parameters = url.searchParams
        // if we get parameter from url, do login
        if (parameters.has('code') && parameters.has('state')) {
          const authParams = {
            code: parameters.get('code'),
            state: parameters.get('state')
          }
          store
            .dispatch('user/login', authParams)
            .then(() => redirect(`/?code=${authParams.code}`))
            .finally(() => {
              resolve(true)
              inAppBrowserRef.close()
            })
        }
      }
    })
  }
}

export default {
  handleInAppBrowserResponse(redirect, type, store, inAppBrowserRef = null) {
    return new Promise((resolve) => {
      document.addEventListener('deviceready', () => {
        if (inAppBrowserRef) {
          const cb = async (params) => {
            inAppBrowserRef.removeEventListener(handlers[type], cb)
            const result = await handlers[type](
              params,
              redirect,
              store,
              inAppBrowserRef
            )
            resolve(result)
          }
          inAppBrowserRef.addEventListener('loadstart', cb)
        }
      })
    })
  },
  handlers
}
