import constants from './constants'
import Storage from '~/lib/storage'

export function setItems(state, items) {
  state.items = items
}

export function addItem(state, item) {
  state.items.unshift(item)
  Storage.setItem(constants.NAMESPACE, state.items)
}
