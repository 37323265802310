import { AxiosInstance } from 'axios'
import { Organization } from '~/models/organization/Organization'
import { StatesCarrier } from '~/carrier/organizations/states/StatesCarrier'
import { ThingsCarrier } from '~/carrier/organizations/things/ThingsCarrier'
import { MembersCarrier } from '~/carrier/organizations/members/MembersCarrier'
import { SubscriptionsCarrier } from '~/carrier/organizations/subscriptions/SubscriptionsCarrier'
import { Carrier } from '~/carrier/Carrier'

export class OrganizationCarrier extends Carrier {
  public readonly states: StatesCarrier
  public readonly things: ThingsCarrier
  public readonly members: MembersCarrier
  public readonly subscriptions: SubscriptionsCarrier

  constructor(api: AxiosInstance) {
    super(api, 'organization')
    this.states = new StatesCarrier(api, this.path)
    this.things = new ThingsCarrier(api, this.path)
    this.members = new MembersCarrier(api, this.path)
    this.subscriptions = new SubscriptionsCarrier(api, this.path)
  }

  public get() {
    return this.api.get(this.path)
  }

  public put(organization: Organization) {
    return this.api.put(`${this.path}/${organization.id}`, organization)
  }

  public post(organization: Organization) {
    return this.api.post(this.path, organization)
  }

  public delete(id: string) {
    return this.api.delete(`${this.path}/${id}`)
  }
}
