import { get } from 'lodash-es'
import { Interceptor } from '~/plugins/api/api-factory/interceptors/interceptor'
import { types } from '~/plugins/api/api-factory/interceptors/constants'

export class InvalidApiKeyInterceptor extends Interceptor {
  static TYPE = types.RESPONSE
  static _ERROR_TYPE = 'INVALID_API_KEY'
  static _ERROR_STATUS = 403

  constructor(store) {
    super()
    this._store = store
  }

  onError(error) {
    const { _ERROR_STATUS, _ERROR_TYPE } = InvalidApiKeyInterceptor
    const status = get(error, ['response', 'status'], null)
    const errorType = get(error, ['response', 'headers', 'x-ErrorType'], null)

    if (status === _ERROR_STATUS && errorType === _ERROR_TYPE) {
      this._store.commit('connection/isApiKeyValid', false)
    }

    return super.onError(error)
  }
}
