import slug from 'slug'
import { IGetNewsDto } from '~/carrier/news/input/IGetNewsDto'

function clean(txt: string = ''): string {
  return txt.replace(/<(\/?)\w+?.+?>/gim, ' ').trim()
}

export function stripHTMLTags(txt: string = ''): string {
  return txt.replace(/(<([^>]+)>)/gi, '').trim()
}

export class News {
  public id = ''
  public imageUrl = ''
  public title = ''
  public teaser = ''
  public text = ''

  public static fromJson(dto: IGetNewsDto) {
    const news = new News()
    const title = clean(dto.title)

    news.id = slug(title)
    news.imageUrl = dto.thumb
    news.title = title
    news.teaser = stripHTMLTags(dto.teaser)
    news.text = dto.text

    return news
  }
}
