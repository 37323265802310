import { AxiosInstance } from 'axios'
import { IGetMembersDto } from './input/IGetMembersDto'
import { Member } from '~/models/organization/member/Member'
import { Carrier } from '~/carrier/Carrier'

export class MembersCarrier extends Carrier {
  constructor(api: AxiosInstance, protected readonly organizationPath: string) {
    super(api, 'members')
  }

  public async get(organizationId: string): Promise<Member[]> {
    const path = `${this.organizationPath}/${organizationId}/${this.path}`
    const { data = {} } = await this.api.get<IGetMembersDto>(path)
    return Object.values(data).map(Member.fromJson)
  }

  public async post(organizationId: string, members: Member[]) {
    const dto = members.map(Member.toPostDto)
    const path = `${this.organizationPath}/${organizationId}/${this.path}`
    const { data = {} } = await this.api.post<IGetMembersDto>(path, dto)
    return Object.values(data).map(Member.fromJson)
  }

  public put(organizationId: string, member: Member) {
    const dto = member.toPutDto()
    const path = `${this.organizationPath}/${organizationId}/${this.path}/${member.id}`
    return this.api.put(path, dto)
  }

  public delete(organizationId: string, id: string) {
    const path = `${this.organizationPath}/${organizationId}/${this.path}/${id}`
    return this.api.delete(path)
  }
}
