const parseParams = (a) => {
  const ret = {}
  const seg = a.search.replace(/^\?/, '').split('&')
  for (let i = 0; i < seg.length; i++) {
    if (!seg[i]) {
      continue
    }
    const s = seg[i].split('=')
    ret[s[0]] = s[1]
  }
  return ret
}

export const parseURL = (url) => {
  const a = document.createElement('a')
  a.href = url

  const params = parseParams(a)

  return {
    hash: a.hash,
    host: a.hostname,
    hostname: a.hostname,
    href: a.href,
    pathname: a.pathname,
    port: a.port,
    protocol: a.protocol,
    search: a.search,
    searchParams: {
      set: (key, value) => {
        params[key] = value
      },
      has: (key) => {
        return key in params
      },
      get: (key) => {
        if (key in params) {
          return params[key]
        }
        return null
      }
    }
  }
}

export default {
  parse: (url) => {
    try {
      const urlObject = new URL(url)
      // searchParams are not fully supported on old browser, so test for it
      if (urlObject.searchParams) {
        return urlObject
      }
      return parseURL(url)
    } catch (e) {
      return parseURL(url)
    }
  }
}
