import { Capacitor } from '@capacitor/core'

function urlDefaultOptions(isNativeMobile = false) {
  return {
    toolbarcolor: isNativeMobile ? '#ffffff' : null,
    toolbarposition: isNativeMobile ? 'top' : null,
    hidenavigationbuttons: isNativeMobile ? 'yes' : null,
    hideurlbar: null,
    location: null
  }
}

export default (url, target, urlOptionsParam) => {
  const isNativeMobile = Capacitor.isNativePlatform()
  const urlOptions = {
    ...urlDefaultOptions(isNativeMobile),
    ...urlOptionsParam
  }

  const options = []
  for (const key in urlOptions) {
    const value = urlOptions[key]
    if (value) {
      options.push(`${key}=${value}`)
    }
  }
  const joinedOptions = options.join(',')
  if (isNativeMobile) {
    // eslint-disable-next-line no-undef
    return cordova.InAppBrowser.open(url, target, joinedOptions)
  } else {
    window.open(url, target, joinedOptions)
  }
}
