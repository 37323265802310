
import { sortBy, template } from 'lodash-es'
import openUrl from '~/lib/url/openUrl'
import { setLocale } from '~/plugins/i18n/persistLocale'

export default {
  props: {
    groups: { type: Array, required: true },
    username: { type: String, default: null },
    email: { type: String, default: null }
  },
  data: () => ({ showLang: false }),
  computed: {
    dropdownItems() {
      if (this.showLang) {
        return this.languageDropdownItems
      }

      return this.mainDropdownItems
    },
    mainDropdownItems() {
      return this.createDropdownItems(this.groups)
    },
    languageDropdownItems() {
      return sortBy(this.$i18n.locales, 'name').map((item) => ({
        label: item.name,
        icon: this.getLocaleIcon(item.code),
        iconClass: this.getLocaleIconClass(item.code),
        callback: (close) => this.onChangeLanguage(item.code, close)
      }))
    }
  },
  methods: {
    onSelect({ callback }, { close }) {
      callback(close)
    },
    onClickedAway() {
      this.showLang = false
    },
    createDropdownItems(items) {
      return items
        .reduce((result, item) => {
          if (Array.isArray(item)) {
            return [...result, this.createDropdownItems(item)]
          }

          return [...result, { ...item, callback: this.createCallback(item) }]
        }, [])
        .filter(
          (dropdown) =>
            dropdown.key || (Array.isArray(dropdown) && dropdown.length > 0)
        )
    },
    createCallback({ route, action, key, url }) {
      return (close) => {
        if (key === 'language') {
          return this.onLanguage()
        }

        if (route) {
          return this.onRoute(route, close)
        }

        if (action) {
          return this.onAction(action, close)
        }

        if (url) {
          return this.onUrl(url, close)
        }
      }
    },
    onLanguage() {
      this.showLang = true
    },
    onRoute(route, close) {
      this.$router.push(route)
      close()
    },
    onAction(action, close) {
      this.$store.dispatch(action)
      close()
    },
    onUrl(url, close) {
      const compile = template(url)
      openUrl(
        compile({ LANGUAGE: this.$i18n.localeProperties.code.toUpperCase() }),
        '_blank'
      )
      close()
    },
    async onChangeLanguage(locale, close) {
      try {
        setLocale(this.$i18n, locale)
        await this.$api.user.put({ language: locale })
      } catch (e) {}
      this.showLang = false
      close()
    },
    getLocaleIcon(locale) {
      if (locale === this.$i18n.locale) {
        return 'solid/check-circle'
      }

      return 'svg/circle'
    },
    getLocaleIconClass(locale) {
      if (locale === this.$i18n.locale) {
        return '!text-green-700'
      }

      return ''
    }
  }
}
