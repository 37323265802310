import { IStorage } from '~/lib/IStorage'
import { Product } from '~/models/product/Product'
import { StoredProduct } from '~/store/documents/favorites/products/local-store/models/stored-product/StoredProduct'

export class ProductsStore {
  private static readonly STORE_KEY: string = 'documents/favorites/products'

  constructor(private readonly store: IStorage) {}

  public save(products: Product[]) {
    this.store.setItem(ProductsStore.STORE_KEY, products)
  }

  public get products(): Product[] {
    if (this.isEmpty) {
      return this.store
        .getItem(ProductsStore.STORE_KEY)
        .map(StoredProduct.toProduct)
    }

    return []
  }

  private get isEmpty(): boolean {
    return this.store.hasItem(ProductsStore.STORE_KEY)
  }
}
