
import { camelCase, isEmpty } from 'lodash-es'

const classes4position = {
  top: 'origin-bottom bottom-full mb-2',
  topStart: 'origin-bottom-right right-0 bottom-full mb-2',
  topEnd: 'origin-bottom-left left-0 bottom-full mb-2',
  bottom: 'origin-top',
  bottomStart: 'origin-top-right right-0',
  bottomEnd: 'origin-top-left left-0',
  right: 'origin-top-left inset-x-full ml-2 top-0 mt-0',
  rightStart: 'origin-top-left inset-x-full ml-2 top-0 mt-0',
  rightEnd: 'origin-bottom-left left-full ml-2 bottom-0 mt-0 mb-px',
  left: 'origin-top-right right-full top-0 mt-0 mr-2',
  leftStart: 'origin-top-right right-full top-0 mt-0 mr-2',
  leftEnd: 'origin-bottom-right right-full ml-2 bottom-0 mt-0 mr-2 mb-px'
}

export default {
  props: {
    items: { type: Array, default: () => [] },
    searchable: { type: Boolean, default: true },
    menuClass: { type: String, default: '' },
    itemClass: { type: String, default: '' },
    iconClass: { type: String, default: '' },
    position: {
      type: String,
      default: 'bottom-end',
      validator(val) {
        const pos1 = ['top', 'bottom', 'left', 'right']
        const pos2 = [undefined, null, 'start', 'end']
        const [p1, p2] = val.split('-')
        return pos1.includes(p1) && pos2.includes(p2)
      }
    },
    show: { type: Boolean, default: false },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      searchQuery: ''
    }
  },
  computed: {
    filteredItems() {
      if (!this.items || this.items.length < 1) return null
      if (!Array.isArray(this.items[0])) {
        return [this.items.filter(this.filterBySearchQuery)]
      }

      return this.items
    },
    isSearchVisible() {
      return (
        this.searchable &&
        this.items &&
        Array.isArray(this.items) &&
        this.items.length > 9
      )
    },
    classes() {
      const key = camelCase(this.position)
      return classes4position[key]
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.isOpen = val
      }
    }
  },
  mounted() {
    const escapeHandler = (e) => {
      if (this.isOpen && e.key === 'Escape') {
        this.close()
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },
  methods: {
    doClose() {
      if (this.show) return

      this.close()
      this.$emit('clicked-away')
    },
    label(itemLabel) {
      return itemLabel?.includes('.') ? this.$t(itemLabel) : itemLabel
    },
    select(item) {
      if (this.closeOnSelect) {
        this.close()
      }
      if (item) {
        this.$emit('select', item, { open: this.open, close: this.close })
      }
    },
    close() {
      this.isOpen = false
      this.$emit('input', false)
    },
    open() {
      this.isOpen = true
      this.$emit('input', true)
    },
    toggle() {
      if (this.disabled) return
      this.$emit('clicked-away', !this.isOpen)
      this.isOpen = !this.isOpen
    },
    filterBySearchQuery(item) {
      if (isEmpty(this.searchQuery)) {
        return true
      }

      return item?.label?.toLowerCase().includes(this.searchQuery.toLowerCase())
    }
  }
}
