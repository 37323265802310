import { render, staticRenderFns } from "./Inner.vue?vue&type=template&id=2088face"
import script from "./Inner.vue?vue&type=script&lang=js"
export * from "./Inner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,AtomsAlertCounter: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/alert/Counter.vue').default,MoleculesNavigationLink: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/navigation/Link.vue').default,MoleculesNavigationUserMenu: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/navigation/UserMenu.vue').default})
