import Vue from 'vue'

export function set(state, items) {
  state.items = items
}

export function updateItems(state, payload) {
  const keys = Object.keys(payload)
  if (payload && keys.length > 0) {
    keys.forEach((id) => {
      // const id = item.ref
      if (id in state.items) {
        Vue.set(state.items, id, { ...state.items[id], ...payload[id] })
      } else {
        Vue.set(state.items, id, payload[id])
      }
    })
  }
}
