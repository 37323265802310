const { merge } = require('lodash-es')
const {
  required,
  minLength,
  maxLength,
  email,
  url,
  alpha,
  alphaNum,
  sameAs,
  requiredIf
} = require('vuelidate/lib/validators')
const checked = (value) => value === true

const validationRules = require('./validationRules.json')

// TODO: Investigate why jest has a problem to resolve this module
module.exports = {
  /**
   * Build vuelidate validation rules from configuration
   * @param {String} country
   * @param {Object} customDefinitions
   */
  buildValidationRules(country = 'DE', customDefinitions = {}) {
    const definitions = merge(validationRules, customDefinitions)
    const rules = {}

    Object.keys(definitions).forEach((field) => {
      const definition = definitions[field]

      const rule = {}

      if (definition.checked) {
        rule.checked = checked
      }
      if (definition.required === true) {
        rule.required = required
      }
      if (definition.requiredIf) {
        rule.required = requiredIf(definition.requiredIf)
      }
      if (definition.minlength) {
        rule.minlength = minLength(definition.minlength)
      }
      if (definition.maxlength) {
        rule.maxlength = maxLength(definition.maxlength)
      }
      if (definition.alpha) {
        rule.alpha = alpha
      }
      if (definition.alphaNum) {
        rule.alphaNum = alphaNum
      }
      if (definition.email) {
        rule.email = email
      }
      if (definition.url) {
        rule.url = url
      }
      if (definition.sameAs) {
        rule.sameAs = sameAs(definition.sameAs)
      }
      if (definition.pattern) {
        rule.pattern = (v) => new RegExp(definition.pattern, 'ig').test(v)
      }
      // some patterns are based on country
      if (definition.patterns) {
        if (definition.patterns[country.toUpperCase()]) {
          rule.pattern = (v) =>
            new RegExp(
              definition.patterns[country.toUpperCase()].pattern,
              'ig'
            ).test(v)
        } else {
          rule.pattern = (v) => new RegExp(definition.pattern, 'ig').test(v)
        }
      }

      if (definition.customValidation) {
        rule.customValidation = definition.customValidation
      }

      rules[field] = rule
    })

    return rules
  }
}
