import { FetchResult } from '@apollo/client'
import { get } from 'lodash-es'
import { ulid } from 'ulid'
import { ActionHandler, ActionTree } from 'vuex'
import Storage from '~/lib/storage'
import { isULID } from '~/lib/utils/string'
import { ThingDto } from '~/models/thing/Thing'
import {
  RegisteredProduct,
  RegisteredProductDto
} from '~/models/warranty/RegisteredProduct'
import PRODUCT_MUTATION from '~/queries/product.mutation.gql'
import REGISTERED_PRODUCTS_QUERY from '~/queries/warranty/registeredProducts.gql'
import SYNC_REGISTERED_PRODUCTS_MUTATION from '~/queries/warranty/syncRegisteredProducts.mutation.gql'
import { IRootState } from '~/store'
import { WarrantyState } from '~/store/warranty/state'

enum LocalStoreId {
  Things = 'warranty/things'
}

type WarrantyAction = ActionHandler<WarrantyState, IRootState>

type SyncRegisteredProductsResult = {
  syncRegisteredProducts: {
    result: boolean
    registeredProducts: RegisteredProductDto[]
  }
}

const formatThings = function (): ThingDto[] {
  if (!Storage.hasItem(LocalStoreId.Things)) return []
  const things = Storage.getItem(LocalStoreId.Things)
  const formattedThings = things.map((thing: ThingDto) => {
    if (isULID(thing.id)) {
      return thing
    }
    const timestamp = thing.ts_last_seen
      ? parseInt(thing.ts_last_seen)
      : undefined
    return {
      id: ulid(timestamp),
      name: thing.name,
      model: thing.model,
      sn: thing.sn,
      image: thing.image,
      ts_last_seen: thing.ts_last_seen
    }
  })

  // save updated things back to local
  if (formattedThings !== things) {
    Storage.setItem(LocalStoreId.Things, formattedThings)
  }
  return formattedThings
}

export const loadProducts: WarrantyAction = function ({ commit }) {
  if (!Storage.hasItem(LocalStoreId.Things)) {
    return commit('setProducts', [])
  }
  const things = Storage.getItem(LocalStoreId.Things)
  if (!Array.isArray(things)) return // prevent loading corrupted data
  commit('setProducts', things.map(RegisteredProduct.fromThing))
}

// update warranty things in local storage to suitable format for syncing
export const syncProducts: WarrantyAction = function ({ dispatch }) {
  const things = formatThings()
  if (things.length === 0) return
  const client = this.app.apolloProvider?.defaultClient
  return client?.mutate({
    mutation: SYNC_REGISTERED_PRODUCTS_MUTATION,
    variables: {
      input: things
    },
    update: (
      cache: { readQuery: Function; writeQuery: Function },
      result: FetchResult<SyncRegisteredProductsResult>
    ) => {
      if (!result.data) return
      dispatch('clearProducts')
      const query = REGISTERED_PRODUCTS_QUERY
      const data: {
        registeredProducts: RegisteredProductDto[]
      } = cache.readQuery({ query }) ?? { registeredProducts: [] }

      const existingIds = data.registeredProducts.map(
        (registeredProduct) => registeredProduct.id
      )

      // insert registered products to cache
      result.data.syncRegisteredProducts.registeredProducts.forEach(
        (registeredProduct: RegisteredProductDto) => {
          if (existingIds.includes(registeredProduct.id)) return
          data.registeredProducts.push(registeredProduct)
        }
      )

      cache.writeQuery({
        query,
        data
      })
    }
  })
}

// Clear warranty records from local storage and reload store
export const clearProducts: WarrantyAction = function ({ dispatch }) {
  Storage.removeItem(LocalStoreId.Things)
  dispatch('loadProducts')
}

export const extend: WarrantyAction = function (
  { dispatch, rootGetters },
  warrantyData
) {
  const isOnline = rootGetters['queue/isOnline']

  if (isOnline) {
    return dispatch('processExtension', warrantyData)
  }

  return dispatch('addPendingProduct', warrantyData)
}

export const processExtension: WarrantyAction = async function (
  { commit },
  warrantyData
) {
  const client = this.app.apolloProvider?.defaultClient
  await client?.mutate({
    mutation: PRODUCT_MUTATION,
    variables: {
      input: warrantyData
    },
    update: (
      cache: { readQuery: Function; writeQuery: Function },
      result: any = { data: { registerProduct: { registeredProducts: [] } } }
    ) => {
      const query = REGISTERED_PRODUCTS_QUERY
      const data: {
        registeredProducts: {
          results: RegisteredProduct[]
        }
      } = cache.readQuery({ query }) ?? { registeredProducts: [] }
      data.registeredProducts.results.unshift(
        ...result.data.registerProduct.registeredProducts
      )
      cache.writeQuery({
        query,
        data
      })
    }
  })

  // remove pending records from storage
  warrantyData.equipment.forEach((device: object) => {
    const serialNo = get(device, ['serialno'], '').trim()
    commit('removeProduct', serialNo)
  })
}

export const addPendingProduct: WarrantyAction = function (
  { dispatch },
  warrantyData
) {
  // temporarily add records to storage
  warrantyData.equipment.forEach(async (device: object) => {
    await dispatch('store', {
      device,
      orgName: warrantyData.practice,
      isPending: true
    })
  })
  return dispatch('addProcessExtensionToQueue', warrantyData)
}

export const addProcessExtensionToQueue: WarrantyAction = function (
  { dispatch },
  warrantyData
) {
  const action = { action: 'warranty/processExtension', payload: warrantyData }

  return dispatch('queue/add', action, { root: true })
}

export const store: WarrantyAction = function (
  { commit },
  { product = {}, device = {}, isPending = false, orgName }
) {
  const registeredProduct = new RegisteredProduct(
    device.serialno,
    device.articleno,
    device.serialno,
    device.name || product.name,
    product.thumb,
    orgName,
    isPending
  )

  commit('updateProduct', registeredProduct)
}

export default {
  loadProducts,
  extend,
  processExtension,
  addPendingProduct,
  addProcessExtensionToQueue,
  store,
  syncProducts,
  clearProducts
} as ActionTree<WarrantyState, IRootState>
