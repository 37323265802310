// TODO: Remove this when SSO is fully rolled out
// This custom scheme bypasses Nuxt Auth mostly
import { LocalScheme } from '~auth/runtime'
import openUrl from '~/lib/url/openUrl'

export default class VsmScheme extends LocalScheme {
  fetchUser() {
    this.$auth.setUser({})
  }

  async login() {
    await this.$auth.ctx.$mixpanel?.trackLoginEvent()
    if (this.$auth.ctx.$platform.isNative) {
      const success = await this.$auth.ctx.$services.login.loginMobile('_blank')
      if (!success) {
        // when user goes back from login page on Android
        throw new Error('USER_CANCELLED')
      }
      this.$auth.ctx.store.commit('user/animationDone')
    } else {
      openUrl(this.$auth.ctx.$services.login.loginUrl, '_self')
      this.$auth.ctx.store.commit('user/animationDone')
    }
  }

  logout() {
    this.$auth.redirect('logout')
  }

  check() {
    return {
      valid: true
    }
  }
}
