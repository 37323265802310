import { App } from '@capacitor/app'

export default ({ $platform, app }) => {
  App.addListener('backButton', () => handleBack($platform, app))
}

export const handleBack = ($platform, app) => {
  const {
    native: { scanner }
  } = $platform
  if ($platform.isNative && scanner.isScanning) {
    scanner.stopScan()
    return
  }
  app.router.back()
}
