import Vue from 'vue'

export function setLocation(state, location) {
  state.location = Object.assign({}, location)
}

export function clearLocation(state) {
  state.location = null
}

export function setLocations(state, locations) {
  Vue.set(state, 'locations', locations)
  // if only locations array was set, reactivity won't work.
  // watcher aren't triggering and computed properties weren't updated.
  // so I watch for this changed value instead. that works ~dominik.sander 2020-03-10
  Vue.set(state, 'changed', Date.now())
}

export function updateLocation(state, location) {
  if (location.id) {
    location = { [location.id]: location }
  }
  state.locations = { ...state.locations, ...location }
}
