import Vue from 'vue'
// import typeCheck from '~/lib/typeCheck'

/* const typeNotify = `{
    message: String,
    type: String,
    position: String,
    actions: [Object],
    timeout: Number
    ...
  }` */

export function addMessage(state, payload) {
  // typeCheck(payload, typeNotify)
  state.displayedMessages.push(payload)
}

export function removeMessage(state, payload) {
  // typeCheck(payload, '{ message: String, type: Number, ... }')
  const messages = state.displayedMessages.filter((m) => m.id !== payload.id)
  Vue.set(state, 'displayedMessages', [...messages])
}
