
import { notificationIconMap } from '~/lib/utils/icon'

export default {
  computed: {
    isOpen() {
      return this.$store.getters['promiseDialog/isOpen']
    },
    options() {
      return this.$store.getters['promiseDialog/options'] ?? {}
    },
    meta() {
      return notificationIconMap[this.options.type]
    }
  },
  methods: {
    onCancel() {
      this.$store.dispatch('promiseDialog/cancel')
    },
    onConfirm() {
      this.$store.dispatch('promiseDialog/confirm')
    }
  }
}
